import { useEffect, useRef, useCallback } from "react";
import { UpdateVideoStat, StartVideoSession } from "../../Api/VideoMetrics";
import { IPlayerPeakInfo } from "../../Models/IPlayerPeakInfo";
import Player from "video.js/dist/types/player";

interface TelemetryConfig {
    accessToken: string;
    video?: { Id?: number }; // adjust type as needed
    peak?: IPlayerPeakInfo | null;
}

const useTelemetry = (
    player: Player | null,
    { accessToken, video, peak }: TelemetryConfig
) => {
    // Ref for the telemetry interval timer
    const
        peakTimer = useRef<number | null>(null),

        // Ensure StartVideoSession only runs on the first play
        isFirstPlay = useRef(true),

        // This ref is used in telemetry if you track seeking (adjust as needed)
        isSeekingRef = useRef(false),

        // Controller for aborting telemetry API calls, if desired
        controller = useRef(new AbortController()),

        onPlay = useCallback(() => {
            if (!player) {
                console.warn("Player is not available; skipping telemetry initialization.");
                return;
            }

            // Determine video ID (or fallback to 0)
            const videoId = video && video.Id ? video.Id : 0;

            // Start telemetry updates if not already started
            if (!peakTimer.current) {
                console.log("Starting the UpdateVideoStat interval");
                // Start after a short delay
                setTimeout(() => {
                    peakTimer.current = window.setInterval(async () => {
                        try {
                            if (!player || !peak || player.readyState() < 1) {
                                console.log("Player not ready or peak data missing; skipping UpdateVideoStat.");
                                return;
                            }

                            // Gather telemetry data from the player
                            const
                                currentTime = Math.round(player.currentTime() || 0),
                                playbackDuration = Math.round(player.duration() || 0),
                                videoQuality = (player as any).qualityLevels?.()[0]?.height || "Unknown",
                                isMuted = player.muted() ?? false,
                                playbackRate = player.playbackRate() ?? 1,
                                isPaused = player.paused(),
                                isSeeking = isSeekingRef.current,
                                isEnded = player.ended(),
                                timestamp = new Date().toISOString(),
                                buffered = player.buffered(),
                                bufferingDuration = buffered.length ? buffered.end(0) : undefined;

                            let bitrate;
                            let bandwidth;

                            // This direct access triggers the Video.js warning, so note why it’s used.
                            try {
                                const
                                    tech = player.tech(),
                                    vhs = tech && (tech as any).vhs;

                                bitrate = vhs?.playlists?.media()?.attributes?.BANDWIDTH || undefined;
                                bandwidth = vhs?.bandwidth || undefined;
                            } catch (err) {
                                console.warn("Failed to retrieve bitrate or bandwidth, continuing without them:", err);
                            }

                            const data = {
                                currentTime,
                                playbackDuration,
                                videoQuality: videoQuality.toString(),
                                isMuted,
                                playbackRate,
                                isSeeking,
                                isPaused,
                                isEnded,
                                timestamp,
                                session: peak.session,
                                videoId,
                                bufferingDuration,
                                bitrate,
                                bandwidth,
                            };

                            console.log("Sending UpdateVideoStat data:", data);
                            const response = await UpdateVideoStat(data, accessToken, controller.current);
                            console.log("UpdateVideoStat response:", response);
                        } catch (error) {
                            console.error("Failed to update video stats:", error);
                        }
                    }, 30000);
                }, 1000); // Delay interval start by 1 second
            }

            // Start the video session only once on the first play
            if (isFirstPlay.current) {
                isFirstPlay.current = false;
                const
                    deviceType = /Mobile/.test(navigator.userAgent) ? "Mobile" : "Desktop",
                    browser = navigator.userAgent,
                    operatingSystem = navigator.platform;

                StartVideoSession(
                    {
                        videoId,
                        userId: peak ? peak.userId : "",
                        session: peak ? peak.session : "",
                        deviceType,
                        browser,
                        operatingSystem,
                    },
                    accessToken,
                    controller.current
                ).catch((error) => console.error("Failed to start video session:", error));
            }
        }, [player, peak, video, accessToken]);

    // Attach the onPlay callback to the player's "play" event
    useEffect(() => {
        if (!player) return;
        player.on("play", onPlay);
        return () => {
            player.off("play", onPlay);
            if (peakTimer.current) {
                clearInterval(peakTimer.current);
            }
        };
    }, [player, onPlay]);
};

export default useTelemetry;
