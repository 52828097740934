import { useEffect, useState } from "react";
import { ISubscriptionDTO } from "../../../Models/DTOs/ISubscriptionDTO";
import CancelOrPause from "../Forms/CancelOrPause";
import CancelOrPausePopUp from "./CancelOrPausePopUp";
import styled from "styled-components";
import Card from "../Card";
import {DifferenceBetweenDates, IsEligibleForSevenExtraDays} from "../../../Helpers/DateAndTime";

const CancelModalWrapper = styled.div`
    width: 100%;
    max-width: calc(600rem / 16);
    h2,
    p {
        text-align: center;
        margin: 0 0 1rem 0;
    }
`;

interface CancelOrPauseModalProps {
    display: boolean;
    setDisplay: (value: boolean) => void;
    subscription: ISubscriptionDTO;
}

function CancelOrPauseModal({ display, setDisplay, subscription }: CancelOrPauseModalProps) {
    const [extraDays, setExtraDays] = useState<boolean>(true);
    const [displayExtraDays, setDisplayExtraDays] = useState<boolean>(true);
    const [reason, setReason] = useState<string>("Select a reason"); // Add state for the select list

    useEffect(() => {
        const startDate = new Date(subscription.SubscriptionStartDate);
        const endDate = new Date(subscription.SubscriptionEndDate);
        const difference = DifferenceBetweenDates(endDate, startDate);
        const result = IsEligibleForSevenExtraDays(startDate, difference);

        setExtraDays(result);

        if (display) {
            setDisplayExtraDays(result);
        }
    }, [display, subscription]);

    const handleSwitchOffAutoRenewal = (
        setStep: (step: number) => void,
        setReason: (reason: string) => void // Function to reset the select list value
    ) => {
        const resetReasonForLeaving = () => {
            setStep(2); // Set to step 2
            setReason("Select a reason"); // Reset select list value
        };

        document.addEventListener('switchoffAutoRenewal', resetReasonForLeaving);

        return () => {
            document.removeEventListener('switchoffAutoRenewal', resetReasonForLeaving);
        };
    };

    return (
        <CancelOrPausePopUp
            canBeDismissed={true}
            isFullScreen={true}
            openEvent="switchoffAutoRenewal"
            openBool={display} // Correct prop to control visibility
        >
            <Card>
                <CancelModalWrapper>
                <CancelOrPause
                    onSwitchOff={(setStep) => handleSwitchOffAutoRenewal(setStep, setReason)} // Pass setReason here
                />
                </CancelModalWrapper>
            </Card>
        </CancelOrPausePopUp>
    );
}

export default CancelOrPauseModal;

