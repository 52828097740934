import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { PopUpType } from '../../../Models/Enums/PopUpType';
import GetPopUpApiData from "../../../Api/PopUpApi";
import IPopUpDTO from "../../../Models/DTOs/IPopUpDTO";
import IconClose from "../../../Assets/SVGs/Icons/UI/Close";
import { Ease } from "../../../Constants/EasingCurves";
import { Colours } from "../../../Constants/Colours";

const
    Container = styled.div`
        padding: 1rem;
        top: -500%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(10,10,10,0.95);
        display: flex;
        align-items: center;
        justify-content: center;
        &.has-transitions {
            transform: scale(1.1);
            opacity: 0;
            transition: transform .22s ${ Ease.Smooth }, top 0s linear .22s, opacity .22s ${ Ease.Smooth };
        }

        .popup__inner {
            height: 100%;
            width: 100%;
            max-height: calc(900rem/16);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &.is-open {
            top: 0;
            &.has-transitions {
                opacity: 1;
                transform: scale(1);
                transition: transform .22s ${ Ease.Smooth }, top 0s linear, opacity .22s ${ Ease.Smooth };
            }
        }
    `,
    PopUpImageWrapper = styled(NavLink)`
        max-width: 100%;
        max-height: calc(100% - (116rem/16));
        margin: 0 0 1rem 0;
        img {
            display: block;
            margin: 0 auto;
        }
    `,
    PopUpImage = styled.img`
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    `,
    CloseIcon = styled.div`
        height: calc(35rem/16);
        width: calc(35rem/16);
        margin: 0 0 1rem 0;
        cursor: pointer;
        border-radius: calc(4rem/16);
        transition: background .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth };
        svg {
            * {
                fill: white;
            }
        }

        &:hover,
        &:focus {
            background: ${ Colours.SecondaryHighlight };
            box-shadow: 0 0 0 calc(7rem/16) ${ Colours.SecondaryHighlight };
        }
    `;

// Allows us to pass props to child elements
// they otherwise might not be configured to accept.
interface CommonPopUpChildProps {
    handleClosePopUp: () => void;
    isPopUpOpen: boolean;
}

const CancelOrPausePopUp = (props: {

    // Allows child components other than what is returned
    // from the API
    children?: React.ReactNode,

    // If requesting api data, enum value required
    apiEnum?: PopUpType,

    // Determines if 'X' icon is displayed
    canBeDismissed: boolean;

    // Will cover all overlapping elements
    isFullScreen: boolean;

    // Event name that can open the popup. Another component
    // must be configured to dispatch an event with a matching name.
    openEvent?: string;

    // Another method to manually open / close the popup. Better for
    // components that directly render the popup
    openBool?: boolean;

}) => {

    const

        // used to reset the popup delay timer, and check if
        // we're opn a /Read page.
        location = useLocation(),

        // PopUp delay timer. Only applies when apiEnum is passed.
        // 5 minutes for /Read pages, 2 minutes for all others.
        timerDuration = location.pathname.startsWith('/Read') ? 5 * 60 * 1000 : 2 * 60 * 1000,

        [ closePopUp, setClosePopUp ] = useState(true),                         // Closes popup
        [ apiSuccess, setApiSuccess ] = useState(false),                        // True when valid data returned from api
        [ isLoading, setIsLoading ] = useState(false),                          // True while waiting for api data to be returned
        [ popUpApiData, setPopUpApiData ] = useState<IPopUpDTO|null>(null),     // PopUp data returned by API

        // Needed for fetch requests
        controller = new AbortController(),

        handleClosePopUp = () => {

            // reset state
            setClosePopUp(true);

            // re-enable scroll if it was disabled
            document.body.classList.remove('disable-scroll');
        },

        handleOpenPopUp = () => {

            // disable state preventing popup from showing.
            setClosePopUp(false);

            // disable scroll if fullscreen popup
            props.isFullScreen && document.body.classList.add('disable-scroll');
        },

        // Fetches data from api and assigns it to state
        getPopUpData = async () => {

            // Is not loading and api data null OR current api data doesn't match new incoming props...
            if (props.apiEnum !== undefined && !isLoading && (popUpApiData === null || popUpApiData.PopUpType !== props.apiEnum)) {

                // component is now loading
                setIsLoading(true);

                // Get data from api
                GetPopUpApiData(controller, props.apiEnum)
                    .then(response => {
                        if (response && Object.keys(response).length > 0) {
                            setApiSuccess(true);
                            setPopUpApiData(response as IPopUpDTO);
                        }

                        setIsLoading(false);
                    });
            }
        };

    useEffect(() => {

        // Gets PopUp Api data if applicable.
        getPopUpData();

        // If 'openEvent' prop in use, create the event listener
        if (props.openEvent !== undefined && props.openEvent !== undefined) {
            document.addEventListener(props.openEvent, handleOpenPopUp);
            return;
        }

        // Otherwise as long as no apiEnum is defined,
        // open the popup immediately.
        if (props.apiEnum === undefined) {

            // Open the pop up immediately
            setClosePopUp(false);
            return;
        }

        return () => {

            // re-enable scroll if it was disabled
            document.body.classList.remove('disable-scroll');

            // Reset popup data
            setPopUpApiData(null);

            // disable event listener if 'openEvent' in use.
            if (props.openEvent !== undefined && props.openEvent !== undefined) {
                document.removeEventListener(props.openEvent, handleOpenPopUp);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.openBool !== undefined) {
            if (props.openBool) {
                handleOpenPopUp();
                return;
            }

            handleClosePopUp();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.openBool ]);

    useEffect(() => {

        // Timer for showing the popup after the specified duration
        // (only works with api enabled popups that don't also utilize the 'openEvent' prop).
        if (props.apiEnum !== undefined && (props.openEvent === undefined || props.openEvent === '')) {

            const timer = setTimeout(() => {

                // prevent <body> scrolling which popup is open (isFullScreen only)
                handleOpenPopUp();

            }, timerDuration);

            // Cleanup function for the timer
            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.apiEnum, location.pathname ]);

    return (
        <Container
            style={ {
                position: props.isFullScreen ? 'fixed' : 'absolute',
                zIndex: props.isFullScreen ? 999 : 1
            } }
            className={ `${ closePopUp ? 'is-closed' : 'is-open' } ${ props.openEvent !== undefined || props.openEvent !== undefined ? 'has-transitions' : 'no-transitions' }` }
        >
            <div className="popup__inner">

                { /** Close popup button ('X') **/ }
                { props.canBeDismissed && (
                    <CloseIcon
                        onClick={ () => handleClosePopUp() }
                    >
                        { IconClose() }
                    </CloseIcon>
                ) }

                { /** Render pop up api data**/ }
                { apiSuccess && props.apiEnum !== undefined && popUpApiData !== null && (
                    <>
                        <PopUpImageWrapper to={ popUpApiData.DestinationUrl }>
                            <PopUpImage src={ popUpApiData.Image } />
                        </PopUpImageWrapper>

                        { /** Disabled until requested in the future **/ }
                        {/*<NavLinkPinkButton to={ popUpApiData.DestinationUrl } >*/}
                        {/*    { popUpApiData.ButtonText }*/}
                        {/*</NavLinkPinkButton>*/}
                    </>
                ) }

                { /** Elements passed as children **/ }
                { React.Children.map(props.children, (child) => {
                    if (React.isValidElement(child)) {

                        // Pass the handleClosePopUp function to the EmailFreeView component
                        return React.cloneElement(child, { handleClosePopUp, isPopUpOpen: !closePopUp } as CommonPopUpChildProps);
                    }
                    return child;
                }) }
            </div>
        </Container>
    );
};

export default CancelOrPausePopUp;
