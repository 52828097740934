import styled from "styled-components";
import {HeadingType} from "../../../Models/Enums/HeadingType";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import IAuthorDTO from "../../../Models/DTOs/IAuthorDTO";

const Container = styled.div`
    width: 100%;
    margin: 0 0 2rem 0;
    p {
        margin: 0 0 1rem 0;
    }
`;

const VideoContainer = styled.div`
    position: relative;
    flex-basis: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    max-height: calc(100vh - 169px);
    background: #000;
    overflow: hidden;
    border-radius: calc(10rem/16);
    .video-js .vjs-big-play-button {
        position: absolute;
        width: 75px;
        height: 75px;
        background-size: 75px 75px !important;
        top: calc(50% + (32.5rem/16));
        left: calc(50% + (37.5rem/16));
    }
`;

function AuthorAbout(props: { author: IAuthorDTO }) {
    return (
        <Container>
            <Heading type={HeadingType.H3}>
                About the author
            </Heading>

            <PrimaryText>{props.author.Description}</PrimaryText>
            {
                props.author.VideoUrl !== null &&
                props.author.VideoUrl.length > 0 ? (
                    <VideoContainer>
                        <VideoPlayer
                            poster={""}
                            src={props.author.VideoUrl}
                            autoPlay={false}
                            playbackAllowed={true}
                        />
                    </VideoContainer>
                ) : null
            }
        </Container>
    );
}

export default AuthorAbout;
