import { ContentAccessType } from "../Enums/ContentAccessType";
import { ContentType } from "../Enums/ContentType";
import IVideoDTO from "./IVideoDTO";
import ISeriesDTO from "./ISeriesDTO";

export default interface ICarouselContentDTO {
    Id: number;
    Title: string;
    Text: string;
    Season: number;
    Episode: number;
    CategoryId: number;
    Thumbnail: string;
    PortraitThumbnail: string;
    BackgroundImage: string;
    AccessType: ContentAccessType;
    ContentType: ContentType;
    Created: string;
    ReleaseDate: string;
    ReleaseDateEarlyAccess: string;
    IsOriginal: boolean,
    IsFreeView: boolean,
    IsFreeViewApp: boolean,
    IsVisible: boolean,
    IsFavourite: boolean,
    Rating: number;
    IsBookable: boolean,
    IsClickeable: boolean,
    LatestEpisode?: number,
    VideoLinkHLS: string;
    VideoLinkDash: string;
    TrailerLinkHLS: string;
    TrailerLinkDash: string;
    Link: string; // Link for redirects
    OptionalLabel: string;
}

export function createPlaceholderICarouselContentDTO(count: number): ICarouselContentDTO[] {
    return Array.from({ length: count }, (_, i) => ({
        Id: i,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: "",
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: '',
    }));
}

export function transformVideoToCarouselContent(
    video: IVideoDTO
): ICarouselContentDTO {
    return {
        Id: video.Id,
        Title: video.Title,
        Text: video.Description,                                                    // Since ICarouselContentDTO has "Text" instead of "Description"
        Season: video.Season ?? 0,
        Episode: video.Episode,
        CategoryId: video.VideoCategoriesId ?? 0,

        Thumbnail: video.Thumbnail,
        PortraitThumbnail: video.PortraitThumbnail,
        BackgroundImage: video.BackgroundImage,

        // For fields, you might not have a direct mapping for, you can assign defaults or handle them
        AccessType: ContentAccessType.Unlimited,                                    // or from video.AccessLevel if you have a mapping
        ContentType: video.SeriesId ? ContentType.Episode : ContentType.Film,       // or from logic that decides if it’s a film / series
        Created: video.Datetime,                                                    // or a new Date().toISOString() if needed
        ReleaseDate: video.Datetime,                                                // pick from Datetime or DatetimeEarlyAccess
        ReleaseDateEarlyAccess: video.DatetimeEarlyAccess,

        IsOriginal: video.IsOriginal,
        IsFreeView: video.IsFreeView,
        IsFreeViewApp: video.IsFreeViewApp,
        // Depending on your logic, might default these to `true` if not available:
        IsVisible: true,
        IsFavourite: false,

        Rating: video.Rating,
        IsBookable: false,
        IsClickeable: true,
        LatestEpisode: undefined,

        // If your API provides separate HLS and Dash links, put them here.
        // Otherwise, set them to empty or derive from something else in video.
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",

        Link: video.Link,
        OptionalLabel: '',
    };
}

export function transformSeriesToCarouselContent(
    series: ISeriesDTO
): ICarouselContentDTO {
    return {
        Id: series.Id,
        Title: series.Title,
        Text: "Series",

        // Depending on what you want to do with series
        // (maybe you treat them like Season=0, Episode=0, or some placeholders?)
        Season: 0,
        Episode: 0,
        CategoryId: series.CategoryId,

        // For series, you might have different images for “cover,” “header,” etc.
        // so pick which ones best fit the layout
        Thumbnail: series.HeaderImg,              // or a fallback to something else
        PortraitThumbnail: series.SeriesCover,          // or a custom portrait asset
        BackgroundImage: series.BackgroundImage,

        AccessType: ContentAccessType.Unlimited,        // or map from series.AccessLevel if needed
        ContentType: ContentType.Series,
        Created: series.Datetime,
        ReleaseDate: series.Datetime,
        ReleaseDateEarlyAccess: "",                     // series might not have this
        IsOriginal: series.IsOriginal,
        IsFreeView: false,                              // depends on your logic
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: series.Rating,
        IsBookable: false,
        IsClickeable: true,
        LatestEpisode: undefined,
        // For series, you might not have direct HLS or Dash links,
        // so either set empty strings or omit.
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: series.TrailerLink,             // If you happen to have only a single link
        TrailerLinkDash: "",
        Link: "",                                        // series might not have a “link” the same way, so either build or leave blank
        OptionalLabel: '',
    };
}
