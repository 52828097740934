import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { GetFilms } from "../../../../Api/Carousel";
import CarouselItem from "../../../../Components/Carousels/CarouselItem";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import ICarouselContentDTO from "../../../../Models/DTOs/ICarouselContentDTO";
import { PopUpType } from "../../../../Models/Enums/PopUpType";
import PopUp from "../../../../Components/UI/Modals/PopUp";
import Banner from "../../../../Components/UI/Banner";
import { BannerType } from "../../../../Models/Enums/BannerType";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";

// Context
import { UserAuthenticationContext } from "../../../../Context/UserAuthenticationContext";
import {SetTitle} from "../../../../Helpers/PageMetadata";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const take = 30;

function FilmsScreenFree() {
    const authCtx = useContext(UserAuthenticationContext);
    const [films, setFilms] = useState<ICarouselContentDTO[]>([]);
    const [initialFilms, setInitialFilms] = useState<ICarouselContentDTO[] | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        // Wait for user data
        if (!authCtx.userData) {
            return;
        }

        async function fetchInitialFilms() {
            setLoadingInitial(true);
            const result = await GetFilms(
                authCtx.userData.CurrentCountryCode,
                authCtx.userData.AspNetUserId,
                take,
                0
            );

            if (result === null || result instanceof AxiosError) {
                setInitialFilms([]);
            } else {
                setInitialFilms(result);
            }

            // Sets the html document <title>
            SetTitle("Films");
            setLoadingInitial(false);
        }

        fetchInitialFilms();
    }, [authCtx.userData]);

    async function loadMoreFilms(skip: number) {
        if (!authCtx.userData) {
            return;
        }

        const result = await GetFilms(
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip
        );

        if (!result || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setFilms((prev) => [...prev, ...result]);
    }

    if (loadingInitial) {
        return (
            <>
                <PopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Films}
                    isFullScreen={true}
                />

                <Banner apiEnum={BannerType.Films} />

                <Container>
                    <ListContainer>
                        {[...Array(take)].map((_, i) => (
                            <VideoSingleLoader paddingTop="0" key={i} />
                        ))}
                    </ListContainer>
                </Container>
            </>
        );
    }

    const mergedLists = initialFilms ? [...initialFilms, ...films] : [];

    return (
        <>
            <PopUp
                canBeDismissed={true}
                apiEnum={PopUpType.Films}
                isFullScreen={true}
            />

            <Banner apiEnum={BannerType.Films} />

            <Container>
                <InfiniteScroll
                    dataLength={mergedLists.length}
                    hasMore={hasMore}
                    next={() => loadMoreFilms(mergedLists.length)}
                    loader={
                        <ListContainer>
                            {[...Array(5)].map((_, i) => (
                                <VideoSingleLoader paddingTop="calc(16rem/16)" key={i} />
                            ))}
                        </ListContainer>
                    }
                >
                    <ListContainer>
                        {mergedLists.map((content, index) => {
                            return (
                                <CarouselItem
                                    key={index.toString() + content.Id}
                                    Content={content}
                                    disableOverlay={true}
                                />
                            );
                        })}
                    </ListContainer>
                </InfiniteScroll>
            </Container>
        </>
    );
}

export default FilmsScreenFree;
