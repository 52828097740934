import {useEffect, useState} from "react";

// Models
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";

// Api
import {GetNewAndCurrentAffairs} from "../../Api/Carousel";

function useFetchPresenters(userId: string) {
    const
        [presenters, setPresenters] = useState<ICarouselDTO | null>(null),
        [presentersLoading, setPresentersLoading] = useState<boolean>(false),
        [presentersError, setPresentersError] = useState<string>('');

    useEffect(() => {
        let isCancelled = false;
        setPresentersLoading(true);

        (async () => {
            try {
                const data = await GetNewAndCurrentAffairs(userId);
                if (!isCancelled) {
                    //console.log(data);

                    const desiredTitlesOrder = [
                        "This Week on the Web",
                        "Gareth Icke Tonight",
                        "Messengers with Christianne van Wijk",
                        "Wake Up Call with Kristen Bitting",
                        "The Dot Connector with David Icke",
                        "Classified with Richard Willett",
                        "WTAF With Rich & Gaz",
                        "Health Code with Jaymie Icke",
                    ];

                    const sortedContent = [...data.Content].sort((a, b) => {
                        const indexA = desiredTitlesOrder.indexOf(a.Title);
                        const indexB = desiredTitlesOrder.indexOf(b.Title);

                        // If a.Title doesn't exist in desiredTitlesOrder, indexA is -1
                        // If b.Title doesn't exist in desiredTitlesOrder, indexB is -1
                        // We push them to the bottom by sorting them *after* all real indices
                        const safeIndexA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
                        const safeIndexB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

                        return safeIndexA - safeIndexB;
                    });

                    // Update the data with the sorted content
                    const updatedData = {
                        ...data,
                        Content: sortedContent
                    };

                    setPresenters(updatedData);
                }
            } catch (err) {
                if (!isCancelled) {
                    setPresentersError('Failed to fetch presenters.');
                    console.error(err);
                }
            } finally {
                if (!isCancelled) {
                    setPresentersLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, []);

    return { presenters, presentersLoading, presentersError };
}

export default useFetchPresenters;
