import {useEffect, useState} from "react";

// Models
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";

// Api
import {GetLatest} from "../../Api/Carousel";

function useFetchJustReleased(userId: string | null, countryCode: string) {
    const
        [justReleased, setJustReleased] = useState<ICarouselDTO | null>(null),
        [justReleasedLoading, setJustReleasedLoading] = useState<boolean>(false),
        [justReleasedError, setJustReleasedError] = useState<string>('');

    useEffect(() => {
        let isCancelled = false;

        console.log('fetching just released');

        if (userId !== null) {
            setJustReleasedLoading(true);

            (async () => {
                try {
                    const data = await GetLatest(countryCode, userId);
                    if (!isCancelled) {
                        //console.log(data);
                        setJustReleased(data); // data is guaranteed to be ICarouselContentDTO
                    }
                } catch (err) {
                    if (!isCancelled) {
                        setJustReleasedError('Failed to fetch just released.');
                        console.error(err);
                    }
                } finally {
                    if (!isCancelled) {
                        setJustReleasedLoading(false);
                    }
                }
            })();
        }

        return () => {
            isCancelled = true;
        };
    }, [userId, countryCode]);

    return { justReleased, justReleasedLoading, justReleasedError };
}

export default useFetchJustReleased;
