import { createContext, useState, ReactNode } from "react";
import lscache from "lscache";

// Models
import { IUserDetails } from "../Models/Interfaces/IUserDetails";

// Api
import { GetUserDetails } from "../Api/Account";

// Define the shape of your context's value
interface UserAuthenticationContextTypes {
    userData: IUserDetails;
    setUserData: (userData: IUserDetails) => void;
    userDataFirstFetchComplete: boolean;
    doesAuthTokenExist: () => boolean;
    storeToken: (token: string, rememberMe: boolean) => void;
    refreshUserData: (token: string) => void;
    fetchUserData: (token: string) => Promise<IUserDetails | null>;
    logOutUser: () => void;
}

export const UserAuthenticationContext = createContext<UserAuthenticationContextTypes>({} as UserAuthenticationContextTypes);

export const UserAuthenticationContextProvider = (props: { children: ReactNode }) => {
    const
        cachedUserData = lscache.get("userData") as IUserDetails | null,
        [userData, setUserDataState] = useState<IUserDetails>(
            cachedUserData ? cachedUserData : ({} as IUserDetails)
        ),

        [userDataFirstFetchComplete, setUserDataFirstFetchComplete] = useState<boolean>(false),

        doesAuthTokenExist = () => {
            // First, check localStorage via lscache
            const localToken = lscache.get("authToken");
            if (localToken) {
                // console.log("Has local token");
                // console.log(localToken);
                return true;
            }

            // Otherwise, check sessionStorage
            const sessionToken = sessionStorage.getItem("authToken");
            // if (sessionToken) {
            //     console.log("Has session token");
            //     console.log(sessionToken);
            // }

            return !!sessionToken;
        },

        storeToken = (token: string, rememberMe: boolean) => {
            if (rememberMe) {
                // Store in localStorage (via lscache) so it persists across sessions
                lscache.set("authToken", token);
            } else {
                // Store in sessionStorage so it's cleared when the browser is closed
                sessionStorage.setItem("authToken", token);
            }
        },

        fetchUserData = async (token: string): Promise<IUserDetails | null> => {
            const abortController = new AbortController();

            if (token) {
                try {
                    const response = await GetUserDetails(token, abortController);
                    const userDetailsData = response as IUserDetails;

                    if (userDetailsData && userDetailsData.AspNetUserId) {
                        setUserData(userDetailsData);
                        setUserDataFirstFetchComplete(true);
                        return userDetailsData;
                    }
                } catch (error: any) {
                    console.error("Failed to fetch user details", error);
                    throw error;
                }
            }

            return null;
        },

        refreshUserData = async (token: string) => {
            const userDetailsData = await fetchUserData(token);

            // If userDetailsData matches IUserDetails and has a valid AspNetUserId, update state
            if (userDetailsData && userDetailsData.AspNetUserId) {
                setUserData(userDetailsData);
            } else {
                // Optionally handle scenario where no valid user details returned
                // e.g., setUserData({} as IUserDetails);
            }
        },

        setUserData = (newUserData: IUserDetails) => {
            setUserDataState(newUserData);
        },

        logOutUser = () => {

            // Remove auth token from lscache
            lscache.remove("authToken");

            // Clear user data state
            setUserData({} as IUserDetails);
        };

    // useEffect(() => {
    //     const token = lscache.get("authToken");
    //     if (token) {
    //         fetchUserData(token).catch((err) => {
    //             console.error("Failed to refresh user data", err);
    //             // Optionally, handle token expiry or show a logout UI
    //         });
    //     }
    // }, []);

    const value: UserAuthenticationContextTypes = {
        userData,
        setUserData,
        userDataFirstFetchComplete,
        doesAuthTokenExist,
        storeToken,
        refreshUserData,
        fetchUserData,
        logOutUser
    };

    return <UserAuthenticationContext.Provider value={value}>{props.children}</UserAuthenticationContext.Provider>;
};
