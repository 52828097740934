export function ContainsSpecialChars(str: string) {
    const specialChars = new RegExp(/[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/);
    return specialChars.test(str);
}

export function ContainsAt(str: string) {
    const specialChars = new RegExp(/@/);
    return specialChars.test(str);
}

export function ContainsUpperCase(str: string) {
    const regex = new RegExp(/[A-Z]/);
    return regex.test(str);
}

export function IsNameValid(text: string) {
    const regex = new RegExp(/^[a-zA-Z- \u00C0-\u00FF]*$/);

    return regex.test(text);
}

export function IsNullOrWhiteSpace(input: string | undefined) {
    return !input || !input.trim();
}

