import React from "react";
import {NavLink} from "react-router-dom";

interface ButtonPillUppercaseProps {
    label: string,
    className: string,
    link: string,
    icon?: React.ReactNode,
    openInNewWindow?: boolean,
    callbackFunction?: (e: any) => void,
    onClick?: () => void,
}

const ButtonPill: React.FC<ButtonPillUppercaseProps> = ({ label = 'Click Here', className = '', link = '', icon, callbackFunction = () => {} }) => {
    return link === "" ? (
        <button
            className={`ickonic-button ickonic-button--pill button ${className}`}
            onClick={callbackFunction}
        >
            <div className="ickonic-button__inner">
                <span>{label}</span>
                {icon && icon}
            </div>
        </button>
    ) : (
        <NavLink
            to={link}
            className={`ickonic-button ickonic-button--pill button ${className}`}
            onClick={callbackFunction}
        >
            <div className="ickonic-button__inner">
                <span>{label}</span>
                {icon && icon}
            </div>
        </NavLink>
    );
};

export default ButtonPill;
