export interface INotificationBar{
    ButtonLink: string;
    ButtonText: string;
    Heading: string;
    Id: number;
}

export function isNotificationBar(obj: any): obj is INotificationBar {
    return (
        obj !== null &&
        typeof obj === 'object' &&
        typeof obj.ButtonLink === 'string' &&
        typeof obj.ButtonText === 'string' &&
        typeof obj.Heading === 'string' &&
        typeof obj.Id === 'number'
    );
}
