import { useEffect, useState } from "react";

// Api
import { GetEpisodesByYear } from "../../Api/Carousel"; // or wherever GetEpisodesByYear is exported

// Models
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";

function useFetchEpisodesByYear(
    seriesId: number,
    country: string,
    aspNetUserId: string,
    take: number,
    skip: number,
    iteration: number,
    shouldAppendData: boolean,
    sortOrder?: string
) {
    const
        [episodes, setEpisodes] = useState<ICarouselContentDTO[]>([]),
        [episodesLoading, setEpisodesLoading] = useState<boolean>(false),
        [episodesError, setEpisodesError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        // Create an AbortController to allow request cancellation
        const abortController = new AbortController();

        setEpisodesLoading(true);

        (async () => {
            try {
                const data = await GetEpisodesByYear(
                    seriesId,
                    country,
                    aspNetUserId,
                    take,
                    skip,
                    iteration,
                    abortController,
                    sortOrder
                );

                if (!isCancelled && data !== null) {
                    if (shouldAppendData) {
                        setEpisodes((prev) => [...prev, ...data.Content]);
                    } else {
                        setEpisodes(data.Content);
                    }
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setEpisodesError("Failed to fetch episodes by year.");
                }
            } finally {
                if (!isCancelled) {
                    setEpisodesLoading(false);
                }
            }
        })();

        // Cleanup function to avoid updating state after unmount
        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [
        seriesId,
        country,
        aspNetUserId,
        take,
        skip,
        iteration,
        shouldAppendData,
        sortOrder,
    ]);

    return { episodes, episodesLoading, episodesError };
}

export default useFetchEpisodesByYear;
