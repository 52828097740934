import React, { ReactNode, useEffect, useRef, useState } from 'react';
import IconClose from "../../Assets/SVGs/Icons/UI/Close";

interface PopUpProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    padding?: string | number;
    borderRadius?: string | number;
    isDarkMode?: boolean;
}

const PopUpBasic: React.FC<PopUpProps> = ({ isOpen, onClose, children, padding, borderRadius, isDarkMode }) => {
    let closeTimeout: NodeJS.Timeout;
    const
        popupRef = useRef<HTMLDivElement>(null),
        [transitionDirection, setTransitionDirection] = useState<string>("out"),
        handleClose = () => {
            closeTimeout = setTimeout(() => {
                onClose();
            }, 450);
        };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setTransitionDirection("out");
                handleClose();
            }
        };

        if (isOpen) {
            setTransitionDirection("in");
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            if (closeTimeout) {
                clearTimeout(closeTimeout);
            }
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div
            className={`popup popup--basic ${isDarkMode ? 'is-dark-mode' : 'is-light-mode'} ${transitionDirection === "in" ? 'is-transitioning-in' : 'is-transitioning-out'}`}>
            <div className="popup__close" onClick={() => handleClose()}>{IconClose()}</div>
            <div className="popup__content" style={{padding, borderRadius}} ref={popupRef}>
                {children}
            </div>
        </div>
    );
};

export default PopUpBasic;
