import React, { useState, useRef, useEffect, useCallback } from "react";
import moment from "moment";

// Utility

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

// Components
import VideoLikeDislike from "./VideoLikeDislike";
import ShareButtons from "../Menus/ShareButtons";
import {ContentType} from "../../Models/Enums/ContentType";
import VideoRedirects from "../UI/Navigation/VideoRedirects";
import {debounce} from "../../Helpers/Performance";

interface VideoInfoProps {
    video: IVideoDTO;
    isVideoLoading: boolean;
}

const VideoPrimaryInformation: React.FC<VideoInfoProps> = ({ video, isVideoLoading }) => {

    const
        [isExpanded, setIsExpanded] = useState(false),
        [containerHeight, setContainerHeight] = useState<number | null>(null),
        [isLongDescription, setIsLongDescription] = useState(false),

        descriptionRef = useRef<HTMLParagraphElement>(null),
        containerRef = useRef<HTMLDivElement>(null),

        baseContainerHeight = 50,

        toggleDescription = () => {
            setIsExpanded(!isExpanded);
        },

        calculateHeights = useCallback(() => {
            if (descriptionRef.current && containerRef.current) {
                const fullHeight = descriptionRef.current.scrollHeight;
                if (fullHeight > baseContainerHeight) {
                    setIsLongDescription(true);
                } else {
                    setIsLongDescription(false);
                }

                if (isExpanded) {
                    setContainerHeight(fullHeight);
                } else {
                    setContainerHeight(baseContainerHeight);
                }
            }
        }, [isExpanded]);

    // Initial calculation and when isExpanded or video.Description changes
    useEffect(() => {
        calculateHeights();
    }, [isExpanded, video.Description, calculateHeights]);

    // Handle window resize using the imported debounce function
    useEffect(() => {
        const handleResize = debounce(() => {
            calculateHeights();
        }, 100); // Adjust the debounce delay as needed

        window.addEventListener("resize", handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [calculateHeights]);

    return (
        <div className="watch-video__primary-info">
            <div className="primary-info__top-row">
                <div className="primary-info__title-and-date">
                    <p className={`primary-info__date-and-series ${isVideoLoading ? "loader--side-to-side" : "is-loaded"}`}>
                        {!isVideoLoading &&
                            (
                                <>
                                    {moment(video.Datetime).format("Do MMMM YYYY").toString()}{" "}
                                    {video.SeriesId && video.SeriesId > 0 ? `• Series ${video.Season} - Episode ${video.Episode}` : null}
                                </>
                            )
                        }
                    </p>

                    <h1 className={isVideoLoading ? "loader--side-to-side" : "is-loaded"}>
                        {!isVideoLoading && video.Title}
                    </h1>
                </div>

                <div className="primary-info__auxillary-info">
                    <div className="primary-info__rating-and-share">
                        <div>
                            <VideoLikeDislike
                                video={video}
                                isVideoLoading={isVideoLoading}
                            />

                            <ShareButtons
                                displayText={true}
                                title={video.Title}
                                contentType={video.SeriesId == null || video.SeriesId === undefined ? ContentType.Film : ContentType.Episode}
                            />
                        </div>
                    </div>

                    <VideoRedirects videoId={video.Id}/>
                </div>
            </div>

            <div
                ref={containerRef}
                className={`
                    primary-info__description-container u-relative_hidden
                    ${isLongDescription ? 'has-long-description' : 'has-short-description'}
                    ${isExpanded ? 'expanded' : 'collapsed'}
                    ${!isVideoLoading && (video.Description === null || video.Description === undefined) ? 'has-no-description' : 'has-description'}
                `}
                style={{
                    height: containerHeight !== null ? `${containerHeight + (isLongDescription ? (isExpanded ? 75 : 65) : 32)}px` : 'auto',
                    transition: 'height 0.3s ease',
                    overflow: 'hidden',
                }}
            >
                {video.Description !== null && video.Description !== undefined && (
                    <>
                        {isVideoLoading ?
                            (<div className="primary-info__description loader--side-to-side"/>)
                            :
                            (
                                <p ref={descriptionRef} className="primary-info__description">
                                    {video.Description}
                                </p>
                            )
                        }

                        {isLongDescription && (
                            <>
                                <div className="primary-info__gradient-shield"/>

                                <button className="ickonic-button" onClick={toggleDescription}>
                                    {isExpanded ? 'Show Less' : '...More'}
                                </button>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default VideoPrimaryInformation;
