import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Components
import InputWithLabel from "../UI/Inputs/InputWithLabel";
import NavLinkPinkTextButton from "../UI/Buttons/NavLinkPinkTextButton";

// Helpers

// Api
import EmailFreeViewAPI from "../../Api/EmailFreeView";

// Constants
import { RoutePaths } from "../../Constants/RoutePaths";

// Assets
import IconCheckMark from "../../Assets/SVGs/Icons/YesOrNo/CheckMark";
import IconSpinLoader from "../../Assets/SVGs/Icons/Loading/SpinLoader";
import InputField from "../Inputs/InputField";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import {IUserDetails} from "../../Models/Interfaces/IUserDetails";

import {ContainsAt} from "../../Helpers/Strings";

interface EmailFreeViewProps {
    handleClosePopUp?: () => void,
    isPopUpOpen?: boolean,
    onAccessGranted: () => void;
}


const EmailFreeView: React.FC<EmailFreeViewProps> = ({
    handleClosePopUp,
    isPopUpOpen,
    onAccessGranted
}) => {
    const
        cookieName = 'emilFreeview',
        [ email, setEmail ] = useState(''),
        [ validEmail, setValidEmail ] = useState(false),
        [ showForm, setShowForm ] = useState(false),
        [ isLoading, setIsLoading ] = useState(false),
        [ success, setSuccess ] = useState(false),
        [ emailError, setEmailError ] = useState<string | undefined>(),
        controller = new AbortController(),

        // Updates email field value on user input
        handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },

        // Standard for proper email format
        validateEmail = (text: string, allowErrors: boolean) => {
            if (text.length <= 0 || text.trim().length <= 0) {
                allowErrors && setEmailError("E-mail cannot be empty");
                setValidEmail(false);
                return false;
            }

            if (!ContainsAt(text)) {
                allowErrors && setEmailError("Not a valid email address");
                setValidEmail(false);
                return false;
            }

            setEmailError(undefined);
            setValidEmail(true);
            return true;
        },

        // Sends user email address to the freeview api
        submitEmail = () => {

            // Checks is email is valid format before proceeding
            if (validateEmail(email, true)) {

                // enable loading status
                setIsLoading(true);
                EmailFreeViewAPI(controller, email)
                    .then((response) => {

                        // If we get a successful response from the api
                        if (response) {

                            // clear the email field
                            setEmail('');

                            // Set indefinite cookie
                            Cookies.set(cookieName, '');

                            // set success status
                            setSuccess(true);

                            onAccessGranted();
                        }

                        // turn off loading regardless of api response
                        setIsLoading(false);
                    })
            }
        },

        handleBlur = () => {
            validateEmail(email, true);
        },

        handleSubmit = (event?: React.MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();
            submitEmail();
        },

        // Create a new function that calls handleSubmit with the event parameter
        handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
            handleSubmit(event);
        },

        handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                submitEmail();
            }
        };

    // Runs once, on component mount
    useEffect(() => {
        if (Cookies.get(cookieName) === undefined) {
            setShowForm(true);
        }
    }, []);

    useEffect(() => {
        if (Cookies.get(cookieName) !== undefined && handleClosePopUp !== undefined) {
            handleClosePopUp();
        }
    }, [ isPopUpOpen, handleClosePopUp ]);

    useEffect(() => {
        setTimeout(() => {
            if (success && handleClosePopUp) {
                handleClosePopUp();
            }
        }, 1500);
    }, [ success, handleClosePopUp ]);

    useEffect(() => {
        validateEmail(email, false);
    }, [ email ]);

    return (
        <div className="watch-video__email-free-view">
            <p className="email-free-view__error-message">
                {emailError}
            </p>

            <InputField
                type="email"
                name="email"
                hasError={ email !== "" && !validEmail }
                value={ email }
                onChange={ handleChange }
                onFocus={() => {}}
                onBlur={ handleBlur }
                className=""
                label="Email"
                errorMessage=""
            />

            <button
                className={ `ickonic-button ${ success ? 'has-success' : 'is-waiting' } ${validEmail ? "is-valid" : "is-not-valid"}` }
                onClick={ (event) => handleClick(event) }
                disabled={ !validEmail }
            >
                { success ?
                    <div className="button__icon icon--success">{ IconCheckMark() }</div>
                    :
                    isLoading ?
                        <div className="button__icon icon--loading">{ IconSpinLoader() }</div>
                        :
                        'Submit'
                }
            </button>

            <p>
                By clicking submit you're agreeing to allow us to contact you with offers and new releases. Please see our <NavLinkPinkTextButton to={ RoutePaths.TermsOfUse }>terms and conditions</NavLinkPinkTextButton> for more informations.
            </p>
        </div>
    );
};

export default EmailFreeView;
