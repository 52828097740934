import {SubscriptionStatus} from "../Models/Enums/SubscriptionStatus";
import {IUserDetails} from "../Models/IUserDetails";
import {ContentAccessType} from "../Models/Enums/ContentAccessType";
import {PlanPeriod} from "../Models/Enums/PlanPeriod";
import {AddToPersistentStorage} from "./LocalStorage";
import {LocalStorageKeys} from "../Constants/LocalStorageKeys";
import moment from "moment/moment";


export function GetAccessLevelTitle(accessLevel: number) {
    switch (accessLevel) {
        case 5:
        case 1:
            return "Two Devices";
        case 4:
        case 2:
            return "One Device";
        case 3:
            return "Four Devices";
        case 99:
            return "Admin";
        default:
            return "None";
    }
}

export function GetRenewStatusTitle(subscriptionStatus: SubscriptionStatus) {
    switch (subscriptionStatus) {
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Cancelled:
        case SubscriptionStatus.Unpaid:
            return SubscriptionStatus[subscriptionStatus];
        case SubscriptionStatus.Past_Due:
            return "Past Due";
        case SubscriptionStatus.Incomplete_Expired:
            return "Incomplete Expired";
        default:
        case SubscriptionStatus.None:
            return "n/a";
    }
}

export function SetLoginAfterToNow() {
    AddToPersistentStorage(LocalStorageKeys.LoginAfter, moment());
}

export function CanUpgrade(userDetails: IUserDetails) {
    const disableUpgrade =
        (userDetails.ContentAccessType === ContentAccessType.FourDevices && userDetails.Period === PlanPeriod.Yearly) ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Cancelled ||
        userDetails.SubscriptionStatus === SubscriptionStatus.None ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Past_Due ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Paused ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Unpaid ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Incomplete_Expired ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Incomplete ||
        userDetails.ContentAccessType === ContentAccessType.GameChanger;

    return !disableUpgrade;
}

export function IsAuthenticated(userDetails: IUserDetails): boolean {
    if (userDetails.Access_Token == null || userDetails.Access_Token.length === 0) {
        return false;
    }
    return true;
}

export function NeedsRenew(userDetails: IUserDetails): boolean {

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Unpaid:
        case SubscriptionStatus.Incomplete:
            return true;
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Cancelled:
        case SubscriptionStatus.None:
        case SubscriptionStatus.Paused:
        default:
            return false;
    }
}

export function IsActive(userDetails: IUserDetails): boolean {

    if (userDetails.AccessLevel === 99) {
        return true;
    }

    switch (userDetails.SubscriptionStatus) {
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Cancelled:
            return true;
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.None:
        case SubscriptionStatus.Paused:
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Unpaid:
        default:
            return false;
    }
}
