import { useEffect, useState } from "react";
import { GetVideo } from "../../Api/Video"; // Update path if needed
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

function useFetchVideo(id: number | undefined, countryCode: string) {
    const
        [video, setVideo] = useState<IVideoDTO | null>(null),
        [videoLoading, setVideoLoading] = useState<boolean>(false),
        [videoError, setVideoError] = useState<string>("");

    useEffect(() => {
        // If ID is invalid or not provided, you can early-return or handle it differently
        if (!id) return;

        let isCancelled = false;
        const abortController = new AbortController();

        setVideoLoading(true);

        (async () => {
            try {
                const data = await GetVideo(id, countryCode, abortController);
                if (!isCancelled) {
                    setVideo(data);
                }
            } catch (err) {
                if (!isCancelled) {
                    setVideoError("Failed to fetch video.");
                    console.error(err);
                }
            } finally {
                if (!isCancelled) {
                    setVideoLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
            abortController.abort(); // Ensures the request is canceled if the component unmounts
        };
    }, [id, countryCode]);

    return { video, videoLoading, videoError };
}

export default useFetchVideo;
