import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";

// Assets
import ButtonsPrevNext from "../../Buttons/ButtonsPrevNext";

// Models
import ICarouselContentDTO, { createPlaceholderICarouselContentDTO } from "../../../Models/DTOs/ICarouselContentDTO";

// Constants
import ButtonPill from "../../Buttons/ButtonPill";

import {GetContentRedirect} from "../../../Helpers/Content";

type CarouselPortraitProps = {
    isDarkMode: boolean;
    heading: string;
    carouselItems?: ICarouselContentDTO[];
    renderItem?: (item: ICarouselContentDTO, isLoading: boolean) => React.ReactNode;
    buttonText?: string;
    buttonLink?: string;
};

const CarouselPortrait: React.FC<CarouselPortraitProps> = (
    {
        isDarkMode,
        heading,
        carouselItems,
        renderItem,
        buttonText,
        buttonLink
    }
) => {
    const
        // If `carouselItems` is undefined, we treat that as "loading".
        // You could also pass a separate `isLoading` prop from the parent if desired.
        isLoading = !carouselItems,

        // We show 6 placeholders while loading; adjust as needed.
        placeholderItems = createPlaceholderICarouselContentDTO(6),

        // Once data is loaded, we display that; otherwise we display placeholders.
        items = isLoading ? placeholderItems : carouselItems,

        containerRef = useRef<HTMLDivElement>(null),

        [currentIndex, setCurrentIndex] = useState(0),
        [itemsPerView, setItemsPerView] = useState(2),
        [containerWidth, setContainerWidth] = useState(0),

        // Decide how many items to show based on window size
        updateItemsPerView = useCallback(() => {
            const width = window.innerWidth;
            if (width < 320) {
                return 1;
            } else if (width < 760) {
                return 2;
            } else if (width < 1008) {
                return 3;
            } else if (width < 1272) {
                return 4;
            } else {
                return 5;
            }
        }, []),

        // Safely compute itemCount and maxIndex
        itemCount = items?.length ?? 0,
        maxIndex = Math.max(0, itemCount - itemsPerView),

        goToPrev = () => {
            setCurrentIndex((prev) => Math.max(prev - 1, 0));
        },

        goToNext = () => {
            setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
        },

        handlers = useSwipeable({
            onSwipedLeft: goToNext,
            onSwipedRight: goToPrev,
            trackMouse: true,
        }),

        // Pixel-based calculations
        itemWidth = containerWidth && itemsPerView ? containerWidth / itemsPerView : 0,
        trackWidth = itemWidth * itemCount,
        translateX = -currentIndex * itemWidth,

        defaultItemRenderer = (item: ICarouselContentDTO, isLoading: boolean) => {
            const redirect = item.IsClickeable ? GetContentRedirect(item.ContentType, item.Id) : null;

            return (
                <NavLink to={redirect ? redirect : '#'} className="u-relative_hidden">
                    { isLoading ?
                        (
                            <div className="loader--side-to-side" />
                        )
                        :
                        (
                            <div
                                className="u-full_cover_absolute"
                                style={{
                                    background: `url("${item.PortraitThumbnail ?? ""}") center/cover no-repeat`,
                                }}
                            />
                        )
                    }
                </NavLink>
            );
        };

    useEffect(() => {
        const handleResize = () => {
            const newItemsPerView = updateItemsPerView();
            setItemsPerView(newItemsPerView);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        handleResize(); // On mount
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateItemsPerView]);

    // If the number of visible items changes, clamp the currentIndex
    useEffect(() => {
        const newMaxIndex = Math.max(0, itemCount - itemsPerView);
        setCurrentIndex((prevIndex) => Math.min(prevIndex, newMaxIndex));
    }, [itemsPerView, itemCount]);

    return (
        <div
            className={`layout--carousel-portrait ${
                isDarkMode ? "is-dark-mode" : "has-light-mode"
            }`}
            {...handlers}
        >
            <div className="carousel-portrait__heading">
                <h2>{heading}</h2>

                {buttonText && buttonLink && (
                    <ButtonPill
                        label={buttonText}
                        className=""
                        link={buttonLink}
                    />
                )}
            </div>


            <div ref={containerRef} className="carousel-portrait__inner">
                <div
                    className="carousel-portrait__track"
                    style={{
                        width: trackWidth ? `${trackWidth}px` : "auto",
                        transform: `translateX(${translateX}px)`,
                    }}
                >
                    {items.map((item, i) => (
                        <div
                            key={i}
                            className="carousel-portrait__item"
                            style={{ width: `${itemWidth}px` }}
                        >
                            {
                                renderItem
                                    ? renderItem(item, isLoading)
                                    : defaultItemRenderer(item, isLoading)
                            }
                        </div>
                    ))}
                </div>
            </div>

            <ButtonsPrevNext
                onPrev={goToPrev}
                onNext={goToNext}
                currentIndex={currentIndex}
                maxIndex={maxIndex}
                parentClass="carousel-portrait__controls"
            />
        </div>
    );
};

export default CarouselPortrait;
