import React, {useContext, useEffect} from "react";

// Components
import ButtonPill from "../Buttons/ButtonPill";
import {GetNotificationBar} from "../../Api/NotificationBar";

// Models
import {isNotificationBar} from "../../Models/DTOs/INotificationBar";

// Context
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";

type NotificationBarProps = {
    isDarkMode: boolean;
};

const NotificationBar: React.FC<NotificationBarProps> = ({ isDarkMode }) => {
    const {setHasNotificationBar} = useContext(GlobalInterfaceContext);



    return (
        <div
            className={`global__notification-bar ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
        >
            <p>
                Trending: CEO Shooter Deep-Dive
            </p>

            <ButtonPill label="Watch Now" className="" link="Watch/1245" />
        </div>
    );
};

export default NotificationBar;
