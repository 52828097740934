import axios from "axios";
import config from "../Constants/Config";

if (!process.env.REACT_APP_API_URL) {
    throw new Error("REACT_APP_API_URL is not defined in the environment variables");
}

const BACKEND_URL = `${process.env.REACT_APP_API_URL}${config.videoMetrics}/`;

async function getCountryInfo(): Promise<string> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.country_code || "Unknown";
    } catch (error) {
        console.error("Error fetching country information:", error);
        return "Unknown";
    }
}

export async function StartVideoSession(
    data: {
        videoId: number;
        userId: string | undefined;
        session: string;
        deviceType: string;
        browser: string;
        operatingSystem: string;
    },
    access_token: string,
    abortController: AbortController
): Promise<void> {
    const countryCode = await getCountryInfo();
    const enhancedData = { ...data, country: countryCode };

    axios
        .post(`${BACKEND_URL}StartVideoSession`, enhancedData, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then(() => {
            console.log("Video session successfully started.");
        })
        .catch((error: any) => {
            console.error("StartVideoSession Error: ", error);
            if (axios.isAxiosError(error) && error.response) {
                console.error("Server response: ", error.response.data);
            }
        });
}

export async function UpdateVideoStat(
    data: {
        currentTime: number;
        playbackDuration: number;
        videoQuality: string;
        isMuted: boolean;
        playbackRate: number;
        isSeeking: boolean;
        isPaused: boolean;
        isEnded?: boolean;
        timestamp: string;
        session: string;
        videoId: number;
        bufferingDuration?: number;
        bitrate?: number;
        bandwidth?: number;
    },
    access_token: string,
    abortController: AbortController
): Promise<boolean> {
    try {
        // Basic data validation
        if (!data.currentTime || !data.session || !data.videoId) {
            console.warn("Invalid data provided to UpdateVideoStat:", data);
            return false;
        }

        // Make the API call
        await axios.post(
            `${BACKEND_URL}UpdateVideoStat`,
            data,
            {
                signal: abortController.signal,
                headers: {
                    Authorization: "Bearer " + access_token,
                },
                timeout: 10000, // 10 seconds timeout
            }
        );

        return true;
    } catch (error: any) {
        console.error("UpdateVideoStat Error with data:", data);
        console.error("Error details:", error);

        if (axios.isAxiosError(error) && error.response) {
            console.error("Server response:", error.response.status, error.response.data);
        }

        return false;
    }
}
