import { useContext } from "react";
import UserOnboarding from "../../Components/GlobalUI/UserOnboarding";

// Models
import {ContentAccessType} from "../../Models/Enums/ContentAccessType";
import {ContentType} from "../../Models/Enums/ContentType";
import {
    CarouselKey,
    CarouselDefinitions,
} from "../../Models/Interfaces/ICarouselDefinition";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { GlobalInterfaceContext } from "../../Context/GlobalInterfaceContext";

// Components
import SliderFeaturedContent from "../../Components/Layouts/Sections/SliderFeaturedContent";
import CarouselPortrait from "../../Components/Layouts/Carousels/CarouselPortrait";
import CarouselLandscape from "../../Components/Layouts/Carousels/CarouselLandscape";
import PortraitPresenterItem from "../../Components/Layouts/Content/PortraitPresenterItem";

// Hooks
import useFetchPresenters from "../../Hooks/Content/useFetchPresenters";
import useFetchJustReleased from "../../Hooks/Content/useFetchJustReleased";
import useFetchFilms from "../../Hooks/Content/useFetchFilms";
import useFetchTopRated from "../../Hooks/Content/useFetchTopRated";
import useFetchCategoryContent from "../../Hooks/Content/useFetchCategoryContent";
import useFetchEpisodesByYear from "../../Hooks/Content/useFetchEpisodesByYear";
import useFetchCustomCarousels from "../../Hooks/Content/useFetchCustomCarousels";

// Assets
import AstroVision from '../../Assets/Images/Browse/ComginSoon/AstroVision.jpg'
import CCC from '../../Assets/Images/Browse/ComginSoon/Cult, Conspiracies and Chaos.jpg';
import PeaceCrime from '../../Assets/Images/Browse/ComginSoon/Peace Crime.jpg';
import Persona from '../../Assets/Images/Browse/ComginSoon/Persona Non Grata.jpg';
import RSZ from '../../Assets/Images/Browse/ComginSoon/rsz_1jones_version_2_final_clean_preview1.jpg';
import TheRide from '../../Assets/Images/Browse/ComginSoon/The Ride - Cycle To The Isle.jpg';

function Browse() {

    // Context
    const
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        authCtx = useContext(UserAuthenticationContext),

        // User Info
        userId = authCtx.userData.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6",
        countryCode = !authCtx.userData.CountryCode || authCtx.userData.CountryCode === ""
            ? "GB"
            : authCtx.userData.CountryCode,

        // Hooks
        { presenters } = useFetchPresenters(userId),
        { justReleased } = useFetchJustReleased(countryCode, userId),
        { films, filmsLoading } = useFetchFilms(countryCode, userId, 30, 0, false),
        { topRated } = useFetchTopRated(countryCode, userId),
        { customCarousels } = useFetchCustomCarousels(countryCode, userId),
        {
            episodes: trendingContent,
            episodesLoading: trendingLoading,
        } = useFetchEpisodesByYear(185, countryCode, userId, 30, 0, 0, false, "d"),
        {
            categoryContent: consciousnessContent,
            contentLoading: consciousnessLoading,
        } = useFetchCategoryContent(5, countryCode, userId, 30, 0, false),
        {
            categoryContent: healthContent,
            contentLoading: healthLoading,
        } = useFetchCategoryContent(21, countryCode, userId, 30, 0, false),
        {
            categoryContent: seriesContent,
            contentLoading: seriesLoading,
        } = useFetchCategoryContent(18, countryCode, userId, 30, 0, false),
        {
            categoryContent: davidIckeContent,
            contentLoading: davidIckeLoading,
        } = useFetchCategoryContent(17, countryCode, userId, 30, 0, false),
        {
            categoryContent: docsContent,
            contentLoading: docsLoading,
        } = useFetchCategoryContent(3, countryCode, userId, 30, 0, false),
        {
            categoryContent: occultContent,
            contentLoading: occultLoading,
        } = useFetchCategoryContent(29, countryCode, userId, 30, 0, false),

        // User preferences
        userPrefString: string | undefined = authCtx.userData?.Person?.PrefCategories,

        // Type guard so TS knows which strings are valid keys
        isCarouselKey = (k: string): k is CarouselKey => {
            return k in CarouselDefinitions;
        },

        // Example: userPrefString => "OriginalFilms,OriginalSeries,HealthAndWellbeing"
        // => userPrefs = ["OriginalFilms","OriginalSeries","HealthAndWellbeing"]
        userPrefs: CarouselKey[] = userPrefString
            ? userPrefString.split(",").filter(isCarouselKey)
            : [],

        // 5) FORCED CAROUSELS (STATIC): We'll define them in the
        //    exact order we want them to appear at the top.
        forcedCarousels: CarouselKey[] = [
            "WeeklyShows",
            "JustReleased",
            "Trending",
            // "CustomCarousels"
        ],

        // We want these 4 to be at the top, in that order.

        // DETERMINE "LEFTOVER" CAROUSELS
        //   => All carousels in your definitions that are NOT in forcedCarousels
        //      and NOT in the user's preferences
        allDefinitionKeys = Object.keys(CarouselDefinitions) as CarouselKey[],

        // If you want to ensure you don't double-include forced items that happen
        // to appear in the user's prefs, you can remove forcedCarousels from userPrefs
        // or just let them appear later—whichever is correct for your scenario.
        // Typically, we'd skip them from user prefs, because they're forced:
        userPrefsWithoutForced = userPrefs.filter(
            (k) => !forcedCarousels.includes(k)
        ),

        // Now leftover = everything that’s neither forced nor in user prefs
        leftoverCarousels = allDefinitionKeys.filter(
            (k) => !forcedCarousels.includes(k) && !userPrefsWithoutForced.includes(k)
        ),

        // BUILD THE FINAL ORDER
        //    forcedCarousels => userPrefs (minus forced) => leftover
        finalLayout: CarouselKey[] = [
            ...forcedCarousels,
            ...userPrefsWithoutForced,
            ...leftoverCarousels,
        ],

        // HELPER: get data for each carousel key
        getCarouselDataByKey = (key: CarouselKey) => {
            switch (key) {
                case "WeeklyShows":
                    return { items: presenters?.Content, loading: false };
                case "JustReleased":
                    return { items: justReleased?.Content, loading: false };
                case "Trending":
                    return { items: trendingContent, loading: trendingLoading };
                // case "CustomCarousels":
                //     return { items: customCarousels, loading: false };
                case "TopRated":
                    return { items: topRated?.Content, loading: false };
                case "DavidIcke":
                    return { items: davidIckeContent, loading: davidIckeLoading };
                case "OriginalFilms":
                    return { items: films, loading: filmsLoading };
                case "OriginalSeries":
                    return { items: seriesContent, loading: seriesLoading };
                case "Consciousness":
                    return { items: consciousnessContent, loading: consciousnessLoading };
                case "HealthAndWellbeing":
                    return { items: healthContent, loading: healthLoading };
                case "Documentaries":
                    return { items: docsContent, loading: docsLoading };
                case "OccultAndHiddenKnowledge":
                    return { items: occultContent, loading: occultLoading };
                default:
                    return { items: [], loading: false };
            }
        },

        // MAP FINAL LAYOUT TO DEFINITIONS & RENDER
        finalCarousels = finalLayout.map((key) => ({
            key,
            def: CarouselDefinitions[key],
        }));

    return (
        <>
            <UserOnboarding isDarkMode={globalInterfaceContext.isDarkMode} />

            <div className="page--browse">

                {/* If you want the SliderFeaturedContent always at top */}
                <SliderFeaturedContent isDarkMode={globalInterfaceContext.isDarkMode} />

                {finalCarousels.map(({key, def}) => {
                    const {items, loading} = getCarouselDataByKey(key);

                    if (def.componentType === "portrait") {
                        // Example: "WeeklyShows" might have a custom item
                        return (
                            <div className="page-browse__carousel-container" key={key}>
                                <CarouselPortrait
                                    heading={def.heading}
                                    isDarkMode={globalInterfaceContext.isDarkMode}
                                    carouselItems={loading ? undefined : items}
                                    buttonText={def.buttonText}
                                    buttonLink={def.buttonLink}
                                    renderItem={
                                        key === "WeeklyShows"
                                            ? (item, isItemLoading) => (
                                                <PortraitPresenterItem
                                                    item={item}
                                                    isLoading={isItemLoading}
                                                    userId={userId}
                                                    countryCode={countryCode}
                                                />
                                            )
                                            : undefined
                                    }
                                />
                            </div>
                        );
                    } else {
                        // "landscape" carousel
                        return (
                            <div className="page-browse__carousel-container" key={key}>
                                <CarouselLandscape
                                    heading={def.heading}
                                    accentColor={def.accentColor}
                                    isDarkMode={globalInterfaceContext.isDarkMode}
                                    carouselItems={loading ? undefined : items}
                                    buttonText={def.buttonText}
                                    buttonLink={def.buttonLink}
                                />
                            </div>
                        );
                    }
                })}

                <div className="page-browse__carousel-container">
                    <CarouselPortrait
                        heading="Coming Soon"
                        isDarkMode={globalInterfaceContext.isDarkMode}
                        carouselItems={comingSoonItems}
                        buttonText=""
                        buttonLink=""
                        renderItem={(item) => (
                            <a style={{cursor: 'default'}} className="u-relative_hidden">
                                <div
                                    className="u-full_cover_absolute"
                                    style={{
                                        background: `url("${item.PortraitThumbnail ?? ""}") center/cover no-repeat`,
                                    }}
                                />

                                {item.OptionalLabel && (<label><span>{item.OptionalLabel}</span></label>)}
                            </a>
                        )}
                    />
                </div>
            </div>
        </>
    );
}

export default Browse;

const comingSoonItems = [
    // {
    //     Id: 1,
    //     Title: "",
    //     Text: "",
    //     Season: 0,
    //     Episode: 0,
    //     CategoryId: 0,
    //     Thumbnail: "",
    //     PortraitThumbnail: RSZ,
    //     BackgroundImage: "",
    //     AccessType: ContentAccessType.Unlimited,
    //     ContentType: ContentType.Film,
    //     Created: "",
    //     ReleaseDate: "",
    //     ReleaseDateEarlyAccess: "",
    //     IsOriginal: false,
    //     IsFreeView: false,
    //     IsFreeViewApp: false,
    //     IsVisible: true,
    //     IsFavourite: false,
    //     Rating: 0,
    //     IsBookable: false,
    //     IsClickeable: false,
    //     LatestEpisode: undefined,
    //     VideoLinkHLS: "",
    //     VideoLinkDash: "",
    //     TrailerLinkHLS: "",
    //     TrailerLinkDash: "",
    //     Link: "#", // Fallback link during loading
    //     OptionalLabel: 'February 8th'
    // },
    {
        Id: 1,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: AstroVision,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: 'March 1st'
    },
    {
        Id: 1,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: CCC,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: 'March 11th'
    },
    {
        Id: 1,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: Persona,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: 'April 12th'
    },
    {
        Id: 1,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: TheRide,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: 'April 29th'
    },
    {
        Id: 1,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: PeaceCrime,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: 'February 22nd'
    }
]
