import {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {Read, ReadByTitle} from "../../Api/Article";
import Heading from "../../Components/UI/Text/Heading";
import IArticleReadDTO from "../../Models/DTOs/IArticleReadDTO";
import {HeadingType} from "../../Models/Enums/HeadingType";
import SharingMetatags from "../../Components/Headers/SharingMetatags";
import PopUp from "../../Components/UI/Modals/PopUp";
import {PopUpType} from "../../Models/Enums/PopUpType";
import {Colours} from "../../Constants/Colours";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";
import HeadingLoader from "../../Components/UI/PageLoaders/HeadingLoader";
import VideoSingleLoader from "../../Components/UI/PageLoaders/VideoSingleLoader";
import AuthorAbout from "../../Components/UI/Article/AuthorAbout";
import ArticleGridItemSingle from "../../Components/UI/Article/ArticleGridItemSingle";
import NextAricle from "../../Components/UI/Article/NextArticle";
import ArticleHeader from "../../Components/UI/Article/ArticleHeader";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";
import {SetTitle} from "../../Helpers/PageMetadata";
import {isValidId} from "../../Helpers/Numbers";
import {IsAuthenticated} from "../../Helpers/Account";

const
    Container = styled.section`
        width: 100%;
        margin: 0 auto;
        background: #1b1b1b;
        * {
            color: white;
        }
    `,
    ArticleInner = styled.div`
        max-width: calc(1350rem/16);
        margin: 0 auto;
        width: 100%;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: center;
        }
    `,
    ContentWrapper = styled.div`
        max-width: calc(700rem/16);
        margin: 0 auto;
        padding: 1rem;
        font-size: 14pt;
        font-weight: 300;
        color: ${ Colours.TextBright };
        box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5), 0 calc(1rem/16) 0 0 rgba(0,0,0,0.5);
        p {
            margin: 0 0 2rem 0;
            &:last-of-type {
                margin: 0;
            }
        }

        .loader--side-to-side {
            width: 100%;
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            width: 60%;
            max-width: none;
            margin: 0;
            padding: 2rem;
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
            font-size: 16pt;
            width: 72%;
            max-width: calc(972rem/16);
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            padding: 3rem;
        }
    `,
    ArticlesGrid = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: calc(16rem/16);
        padding: 1rem;
        h3 {
            margin: 0 0 1rem 0;
            width: 100%;
        }

        > div {
            width: 100%;
            &:last-child {
                margin: 0;
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.MediumMobileBreakpoint}rem/16)) {
                width: calc(50% - (8rem/16));

                // 'About the author' remains 100%
                &:first-child {
                    width: 100%;
                }
            }

            @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
                width: 100%;
            }
        }

        @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
            width: calc(40% - 2rem);
            margin: 0 2rem 0 0;
            padding: 2rem 0 2rem 2rem;
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
            width: calc(28% - 2rem);
            max-width: calc(345rem/16);
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
            padding: 3rem 0 2rem 3rem;
        }
    `,
    DummyHeader = styled.div`
        height: calc(550rem/16);
        width: 100%;
        background: black;
        position: relative;
        overflow: hidden;
    `;

function ArticleDetailsScreen() {
    const
        [ article, setArticle ] = useState<IArticleReadDTO|null>(null),
        [ canFetch, setCanFetch ] = useState<boolean>(true),
        [ isLoading, setIsLoading ] = useState<boolean>(true),
        [ activeArticleId, setActiveArticleId ] = useState<string|number|null>(null),
        controller = new AbortController(),
        { id } = useParams(),
        authCtx = useContext(UserAuthenticationContext),
        fetchArticleData = () => {
            async function GetArticle() {
                const checkById = typeof id === 'string' ? isValidId(id) : false;
                let result;

                if (checkById) {
                    result = typeof id === 'string' ? Read(parseInt(id), controller) : null;
                }
                else {
                    result = typeof id === 'string' ? ReadByTitle(id, controller) : null;
                }
                return result;
            }

            GetArticle()
                .then(response => {
                    const articleData = response as IArticleReadDTO;
                    typeof id === 'string' && setActiveArticleId(id);
                    SetTitle(articleData.Article.Title);
                    setArticle(articleData);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 250);
                })
        };

    useEffect(() => {
        return () => {
            setCanFetch(true);
            setArticle(null);
        }
    }, []);

    useEffect(() => {
        if (activeArticleId === null || id !== activeArticleId) {
            setCanFetch(true);
            setIsLoading(true);
        }
    }, [ id, activeArticleId ]);

    useEffect(() => {
        if (canFetch) {
            setCanFetch(false);
            fetchArticleData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ canFetch ]);

    return (
        <Container>
            { !IsAuthenticated(authCtx.userData) && (
                <PopUp
                    canBeDismissed={ true }
                    apiEnum={ PopUpType.Read }
                    isFullScreen={ true }
                />
            ) }

            { !isLoading && article !== null ?
                <>
                    <SharingMetatags
                        title={article.Article.Title}
                        description={article.Article.SubTitle}
                        url={article.Article.UrlTitle}
                        image={article.Article.Header}
                        type="article"
                    />

                    <ArticleHeader
                        article={article.Article}
                        ReadTime={article.ReadTime}
                        DateToString={article.DateToString}
                    />

                    <ArticleInner>
                        <ContentWrapper>
                            { article.Text && article.Text.length > 0 && article.Text.map((text, i) => (
                                <Fragment key={ i }>
                                    { parse(DOMPurify.sanitize(text)) }
                                </Fragment>
                            )) }
                        </ContentWrapper>

                        <ArticlesGrid>
                            <AuthorAbout author={article.Article.Author} />

                            { article.SuggestedArticles && article.SuggestedArticles.length > 0 ? (
                                <Heading type={ HeadingType.H3}>
                                    Related Articles
                                </Heading>
                            ) : null }

                            { article.SuggestedArticles && article.SuggestedArticles.length > 0 && article.SuggestedArticles.map((article, i) => (
                                <ArticleGridItemSingle
                                    article={article}
                                    key={i}
                                />
                            )) }
                        </ArticlesGrid>

                        <NextAricle article={article.NextArticle} />
                    </ArticleInner>
                </>
                :
                <>
                    <DummyHeader>
                        <div className="loader--side-to-side" style={{ background: 'black' }} />
                    </DummyHeader>

                    <ArticleInner>
                        <ContentWrapper>
                            {Array.from({ length: 32 }, (_, i) => i).map((_, index) => (
                                <HeadingLoader paddingTop={ index === 0 ? "0" : "16" } key={index} />
                            ))}
                        </ContentWrapper>

                        <ArticlesGrid>
                            {Array.from({ length: 4 }, (_, i) => i).map((_, index) => (
                                <VideoSingleLoader paddingTop={ index === 0 ? "0" : "16" } key={index} />
                            ))}
                        </ArticlesGrid>
                    </ArticleInner>
                </>
            }
        </Container>
    );
}

export default ArticleDetailsScreen;
