import axios, { AxiosError } from "axios";
import IAuthor from "../Models/DTOs/IAuthorDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + "/slider/notification/1";
console.log(BACKEND_URL);

export async function GetNotificationBar() {
    const response = await axios.get(BACKEND_URL).then((result) => {
        console.log("GetNotificationBar");
        console.log(result.data);
        return result.data;
    }).catch((error: AxiosError) => {
        console.log("error GetNotificationBarAlt");
        throw error;
    });

    return response
};
