import { Suspense, useContext, useEffect, useState } from "react";
import { HeadingType } from "../../Models/Enums/HeadingType";
import { Colours } from "../../Constants/Colours";
import styled from "styled-components";
import AccountSection from "../../Components/UI/Account/AccountSection";
import PinkButton from "../../Components/UI/Buttons/PinkButton";
import PrimaryText from "../../Components/UI/Text/PrimaryText";
import Heading from "../../Components/UI/Text/Heading";
import StripeCard from "../../Components/UI/StripeCard";
import { PaymentMethod } from "@stripe/stripe-js";
import { AddPaymentMethod } from "../../Api/Stripe";
import { DisconnectFromAllDevices, GetYourAccountDTO } from "../../Api/Account";
import { GetCampaignDetailsHeader, GetCampaignDetailsHeaderByCampaignID } from "../../Api/Campaign";
import { IYourAccountDTO } from "../../Models/DTOs/IYourAccountDTO";
import EditPerson from "../../Components/UI/Account/EditPerson";
import EditEmail from "../../Components/UI/Account/EditEmail";
import EditCommercialEmails from "../../Components/UI/Account/EditCommericalEmails";
import ChangePasswordForm from "../../Components/UI/Account/ChangePasswordForm";
import GreyButton from "../../Components/UI/Buttons/GreyButton";
import UpgradeModal from "../../Components/UI/Modals/UpgradeModal";
import CancelorPauseModal from "../../Components/UI/Modals/CancelOrPauseModal";
import ReferAFriendModal from "../../Components/UI/Modals/ReferAFriendModal";
import { SubscriptionStatus } from "../../Models/Enums/SubscriptionStatus";
import { IUserDetails } from "../../Models/IUserDetails";
import TextLoader from "../../Components/UI/PageLoaders/TextLoader";
import { RoutePaths } from "../../Constants/RoutePaths";
import { ISubscriptionDTO } from "../../Models/DTOs/ISubscriptionDTO";
import { AxiosError } from "axios";
import EditDailyNewsletter from "../../Components/UI/Account/EditDailyNewsletter";
import moment from "moment";
import { toast } from "react-toastify";
import { Fonts } from "../../Constants/Fonts";
import NavLinkPinkButton from "../../Components/UI/Buttons/NavLinkPinkButton";
import PopUp from "../../Components/UI/Modals/PopUp";
import AccountRow from "../../Components/UI/Account/AccountRow";
import PinkTextButton from "../../Components/UI/Buttons/PinkTextButton";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import Card from "../../Components/UI/Card";
import { InvoiceStatus } from "../../Models/Enums/InvoiceStatus";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import {SetTitle} from "../../Helpers/PageMetadata";
import {
    CanUpgrade,
    GetAccessLevelTitle,
    GetRenewStatusTitle,
    NeedsRenew,
    SetLoginAfterToNow
} from "../../Helpers/Account";
import {GetPlanPeriod, GetPlanSubText, GetPlanType, PlanInterval} from "../../Helpers/PlanInformation";
import {SymbolFromCurrency} from "../../Helpers/Currency";

const Container = styled.section`
  padding: 1rem;
  button {
    margin: 0 0 1rem 0;
  }
`;

const MainHeading = styled(Heading)`
  text-align: center;
  margin: 0;
  padding: 2rem 1rem;
  background: ${Colours.Secondary};
  @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}rem/16)) {
    font-size: 25pt;
  }
`;

const ContentSpit = styled.div`
    > h3 {
        margin: 0 0 calc(8rem/16) 0;
    }
`;

const ContentContainer = styled.div``;

const StripeCardContainer = styled.div`
    max-width: calc(600rem/16);
`;

const PlanTable = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PlanElem = styled.div`
    padding: 0 0 calc(10rem/16) 0;
    width: 100%;
    font-family: ${Fonts.Primary};
    h4 {
        margin: 0;
        color: ${Colours.TextDarker};
        font-size: 12pt;
    }

    p {
        font-size: 12pt;
        color: ${Colours.TextBright};
        margin: calc(5rem/16) 0 0 0;
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}rem/16)) {
        flex: 1;
        min-width: 30%;
        padding: 0 calc(25rem/16) calc(25rem/16) 0;
    }
`;

async function fetchYourAccountDetails(
    userDetails: IUserDetails,
    controller: AbortController
): Promise<IYourAccountDTO | null> {
    // console.log(userDetails);
    const result = await GetYourAccountDTO(
        userDetails.AspNetUserId,
        userDetails.Access_Token,
        controller
    );

    if (result === null || result instanceof AxiosError) {
        return null;
    }

    SetTitle("Account");
    return result;
}

function AccountScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [loading, setLoading] = useState<boolean>(true);
    const [accountData, setAccountData] = useState<IYourAccountDTO | null>(null);

    const [displayUpgrade, setDisplayUpgrade] = useState<boolean>(false);
    const [displayCancel, setDisplayCancel] = useState<boolean>(false);
    const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);

    // State for Refer a Friend Section
    const [campaignTitle, setCampaignTitle] = useState<string>();
    const [campaignDescription, setCampaignDescription] = useState<string>();
    const [campaignLinkText, setCampaignLinkText] = useState<string>();
    const [userCampaignDetails, setUserCampaignDetails] = useState({});

    useEffect(() => {
        // Wait until we have user data
        if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
            return;
        }

        // Fetch account details
        async function loadAccount() {
            setLoading(true);
            const result = await fetchYourAccountDetails(authCtx.userData, controller);
            setAccountData(result);
            setLoading(false);
        }

        loadAccount();
    }, [authCtx.userData]);

    // Campaign details effect
    useEffect(() => {
        async function fetchCampaignDetails() {
            try {
                const campaignDetails = await GetCampaignDetailsHeader();
                if (campaignDetails) {
                    const campaignEndDate = new Date(campaignDetails.CampaignEndDate);

                    if (new Date() <= campaignEndDate) {
                        setCampaignTitle(campaignDetails.CampaignAccountsPageHeader);
                        setCampaignDescription(campaignDetails.CampaignAccountsPageDescription);
                        setCampaignLinkText(campaignDetails.CampaignAccountsPageLink);

                        localStorage.setItem("campaignDetails", JSON.stringify(campaignDetails));

                        if (campaignDetails.CampaignID) {
                            const userDetails = await GetCampaignDetailsHeaderByCampaignID(campaignDetails.CampaignID);
                            if (userDetails) {
                                setUserCampaignDetails((prevState) => ({
                                    ...prevState,
                                    ...userDetails,
                                }));
                            }
                        }
                    } else {
                        // Campaign ended
                        setCampaignTitle(undefined);
                        setCampaignDescription(undefined);
                        setCampaignLinkText(undefined);

                        localStorage.removeItem("campaignDetails");
                    }
                }
            } catch (error) {
                console.error("Error fetching campaign details:", error);

                // Fallback to localStorage
                const storedData = localStorage.getItem("campaignDetails");
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const campaignEndDate = new Date(parsedData.CampaignEndDate);
                    if (new Date() <= campaignEndDate) {
                        setCampaignTitle(parsedData.CampaignAccountsPageHeader);
                        setCampaignDescription(parsedData.CampaignAccountsPageDescription);
                        setCampaignLinkText(parsedData.CampaignAccountsPageLink);
                    } else {
                        setCampaignTitle(undefined);
                        setCampaignDescription(undefined);
                        setCampaignLinkText(undefined);
                        localStorage.removeItem("campaignDetails");
                    }
                }
            }
        }

        fetchCampaignDetails();
    }, []);

    useEffect(() => {
        if (!upgradeSuccess) return;

        // Refresh account data after upgrade success
        async function refreshAccountData() {
            const result = await fetchYourAccountDetails(authCtx.userData, controller);
            setAccountData(result);
            setUpgradeSuccess(false);
        }

        refreshAccountData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upgradeSuccess]);

    // If user data or account data hasn't loaded yet, show a loader
    if (loading || !authCtx.userData || !authCtx.userData.AspNetUserId) {
        return <TextLoader />;
    }

    // If we have user data but no account data returned, we can either show an error or redirect
    if (!accountData) {
        toast.error("No account data found.");
        return <TextLoader />;
    }

    // Plan calculations
    const currentPlan = GetPlanType(authCtx.userData.ContentAccessType, authCtx.userData.Period);
    const currentPlanTitle = currentPlan ? GetPlanPeriod(currentPlan) + "ly Plan" : "";
    const currencySymbol = SymbolFromCurrency(authCtx.userData.Currency);
    const canUpgrade = CanUpgrade(authCtx.userData);

    function SetDisplayUpgrade(value: boolean) {
        setDisplayUpgrade(value);
    }

    function SetDisplayCancel(value: boolean) {
        setDisplayCancel(value);
    }

    function SetUpgradeSuceess(value: boolean) {
        setUpgradeSuccess(value);
    }

    async function AddStripePaymentMethodFn(paymentMethod: PaymentMethod) {
        const result = await AddPaymentMethod(authCtx.userData.AspNetUserId, paymentMethod.id, controller);
        if (result instanceof AxiosError) {
            return false;
        }

        // Refresh user data after adding a new payment method
        authCtx.refreshUserData(authCtx.userData.Access_Token);
        return result;
    }

    async function DisconnectOtherDevices() {
        const result = await DisconnectFromAllDevices(authCtx.userData.Access_Token, controller);
        if (result) {
            toast.success("Success! Your other website sessions will be disconnected.");
            SetLoginAfterToNow();
        }
    }

    // For plan display
    const disableCancel =
        accountData.LatestInvoiceStatus !== InvoiceStatus.Paid ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.Cancelled ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.None ||
        authCtx.userData.SubscriptionStatus === SubscriptionStatus.Incomplete_Expired;

    return (
        <>
            <MainHeading>Account Settings</MainHeading>

            <Container>
                <AccountSection title="Your Details">
                    <AccountRow>
                        <PrimaryText>{authCtx.userData.Person?.FirstName}</PrimaryText>
                        <PinkTextButton
                            onClick={() => {
                                document.dispatchEvent(new CustomEvent("editPerson"));
                            }}
                            disabled={false}
                        >
                            Edit Name
                        </PinkTextButton>
                    </AccountRow>

                    <AccountRow>
                        <PrimaryText>{authCtx.userData.Email}</PrimaryText>
                        <PinkTextButton
                            onClick={() => {
                                document.dispatchEvent(new CustomEvent("editEmail"));
                            }}
                            disabled={false}
                        >
                            Edit Email
                        </PinkTextButton>
                    </AccountRow>

                    <AccountRow>
                        <PrimaryText>Password: **********</PrimaryText>
                        <PinkTextButton
                            onClick={() => {
                                document.dispatchEvent(new CustomEvent("changePassword"));
                            }}
                            disabled={false}
                        >
                            Change Password
                        </PinkTextButton>
                    </AccountRow>
                </AccountSection>

                {campaignTitle && campaignDescription && campaignLinkText && (
                    <AccountSection title={campaignTitle}>
                        <ContentSpit>
                            <PrimaryText>{campaignDescription}</PrimaryText>
                            <PinkTextButton
                                onClick={() => {
                                    document.dispatchEvent(new CustomEvent("referAFriend"));
                                }}
                                disabled={false}
                            >
                                {campaignLinkText}
                            </PinkTextButton>
                        </ContentSpit>
                    </AccountSection>
                )}

                <AccountSection title="Email Preferences">
                    <EditCommercialEmails
                        specialOffers={
                            authCtx.userData.AllowCommericalEmails !== undefined
                                ? authCtx.userData.AllowCommericalEmails
                                : false
                        }
                    />
                    <EditDailyNewsletter
                        value={
                            authCtx.userData.AllowDailyNewsletter !== undefined
                                ? authCtx.userData.AllowDailyNewsletter
                                : false
                        }
                    />
                </AccountSection>

                <AccountSection
                    title={"Current Plan"}
                    headingSibling={
                        authCtx.userData.AccessLevel >= 5 && !NeedsRenew(authCtx.userData) ? null : canUpgrade ? (
                            <PinkButton onClick={() => SetDisplayUpgrade(true)}>Upgrade</PinkButton>
                        ) : (
                            <NavLinkPinkButton to={RoutePaths.ChoosePlan}>Choose Plan</NavLinkPinkButton>
                        )
                    }
                >
                    <ContentContainer>
                        <ContentSpit>
                            <Heading type={HeadingType.H3}>{currentPlanTitle}</Heading>

                            <PlanTable>
                                <PlanElem>
                                    <h4>Number of active devices:</h4>
                                    <p>{GetAccessLevelTitle(authCtx.userData.AccessLevel)}</p>
                                </PlanElem>
                                <PlanElem>
                                    <h4>Auto renewal:</h4>
                                    <p>{GetRenewStatusTitle(authCtx.userData.SubscriptionStatus)}</p>
                                </PlanElem>
                                {accountData.Subscription ? (
                                    <>
                                        <PlanElem>
                                            <h4>Start date:</h4>
                                            <p>
                                                {moment(new Date(accountData.Subscription.SubscriptionStartDate)).format(
                                                    "Do MMMM YYYY"
                                                )}
                                            </p>
                                        </PlanElem>
                                        <PlanElem>
                                            <h4>End date:</h4>
                                            <p>
                                                {moment(new Date(accountData.Subscription.SubscriptionEndDate)).format(
                                                    "Do MMMM YYYY"
                                                )}
                                            </p>
                                        </PlanElem>
                                        <PlanElem>
                                            <h4>Billing amount:</h4>
                                            <p>
                                                <sup>{currencySymbol}</sup>
                                                {accountData.Subscription?.BillingAmount}
                                            </p>
                                        </PlanElem>
                                        <PlanElem>
                                            <h4>Billing frequency:</h4>
                                            <p>{PlanInterval(accountData.Subscription.BillingFrequency)}</p>
                                        </PlanElem>
                                    </>
                                ) : null}

                                <PlanElem>
                                    <h4>Payment method:</h4>
                                    <p>{accountData.Last4 ? " " + accountData.Last4.toString() : " n/a"}</p>
                                    <PinkTextButton
                                        onClick={() => {
                                            document.dispatchEvent(new CustomEvent("updateBillingDetails"));
                                        }}
                                        disabled={false}
                                    >
                                        Update Billing Details
                                    </PinkTextButton>
                                </PlanElem>
                            </PlanTable>

                            {accountData.Subscription ? (
                                <>
                                    <PrimaryText>
                                        {GetPlanSubText(
                                            authCtx.userData.SubscriptionStatus,
                                            new Date(accountData.Subscription.SubscriptionEndDate),
                                            accountData.Subscription.BillingFrequency
                                        )}
                                    </PrimaryText>
                                    <PinkTextButton
                                        onClick={() => {
                                            document.dispatchEvent(new CustomEvent("switchoffAutoRenewal"));
                                        }}
                                        disabled={disableCancel}
                                    >
                                        Switch Off Auto Renewal
                                    </PinkTextButton>
                                </>
                            ) : null}
                            <br />
                        </ContentSpit>
                    </ContentContainer>
                </AccountSection>

                <AccountSection title={"Disconnect Devices"}>
                    <ContentSpit>
                        <PrimaryText>
                            After clicking this button, every other device will need to log in again.
                        </PrimaryText>
                        <br />
                        <GreyButton onClick={DisconnectOtherDevices}>Disconnect Devices</GreyButton>
                    </ContentSpit>
                </AccountSection>

                <UpgradeModal display={displayUpgrade} setDisplay={SetDisplayUpgrade} setSuccess={SetUpgradeSuceess} />

                {!disableCancel && accountData.Subscription ? (
                    <CancelorPauseModal
                        display={displayCancel}
                        setDisplay={SetDisplayCancel}
                        subscription={accountData.Subscription as ISubscriptionDTO}
                    />
                ) : null}

                {/* Refer a Friend Modal */}
                <ReferAFriendModal />
            </Container>

            {/* Edit Person PopUp */}
            <PopUp canBeDismissed={true} isFullScreen={true} openEvent="editPerson">
                <Card>
                    <EditPerson />
                </Card>
            </PopUp>

            {/* Edit Email PopUp */}
            <PopUp canBeDismissed={true} isFullScreen={true} openEvent="editEmail">
                <Card>
                    <EditEmail />
                </Card>
            </PopUp>

            {/* Change Password PopUp */}
            <PopUp canBeDismissed={true} isFullScreen={true} openEvent="changePassword">
                <Card>
                    <ChangePasswordForm />
                </Card>
            </PopUp>

            {/* Update Billing Details PopUp */}
            {!displayUpgrade ? (
                <PopUp canBeDismissed={true} isFullScreen={true} openEvent="updateBillingDetails">
                    <StripeCardContainer>
                        <StripeCard setPaymentMethod={AddStripePaymentMethodFn} />
                    </StripeCardContainer>
                </PopUp>
            ) : null}
        </>
    );
}

export default AccountScreen;
