import React, { useContext, useEffect, useRef, useState } from "react";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// Components
import ButtonPill from "../Buttons/ButtonPill";

// Models
import ICategories from "../../Models/Interfaces/ICategories";
import {IPerson} from "../../Models/Interfaces/IPerson";

// Api
import {AddOrEditPerson, GetIsOnboarded} from "../../Api/Person";
import {Colours} from "../../Constants/Colours";
import InputDropDown from "../Inputs/InputDropDown";
import IconCheckMark from "../../Assets/SVGs/Icons/YesOrNo/CheckMark";

const UserOnboarding = (props: { isDarkMode: boolean }) => {
    const
        userAuthenticationContext = useContext(UserAuthenticationContext),
        [onBoardPopUpActive, setOnBoardPopUpActive] = useState(false),
        [allowClosePopUp, setAllowClosePopUp] = useState(false),
        [personData, setPersonData] = useState<Partial<IPerson>>({
            ...userAuthenticationContext.userData.Person,
            PrefCategories: userAuthenticationContext.userData.Person?.PrefCategories || "",
            PrefContentType: userAuthenticationContext.userData.Person?.PrefContentType || "",
            HowDidYouHear: userAuthenticationContext.userData.Person?.HowDidYouHear || "",
        }),
        [currentStep, setCurrentStep] = useState(0),

        popupRef = useRef<HTMLDivElement>(null),
        wrapperRef = useRef<HTMLDivElement>(null),
        stepRefs = useRef<HTMLDivElement[]>([]),
        stepCount = 4,

        contentTypeOptions = [
            "Videos",
            "Articles",
            "Podcasts",
            "Emails",
            "Texts"
        ],

        referrerOptions = [
            "Search Engine",
            "Friend / Word of Mouth",
            "Podcast / 3rd Party Media",
            "SocialAndShare Media"
        ],

        handleCategoryClick = (categoryKey: string) => {
            setPersonData((prevData) => {
                const selectedCategories = prevData.PrefCategories ? prevData.PrefCategories.split(",") : [];
                if (selectedCategories.includes(categoryKey)) {
                    return {
                        ...prevData,
                        PrefCategories: selectedCategories.filter((c) => c !== categoryKey).join(","),
                    };
                } else if (selectedCategories.length < 3) {
                    return {
                        ...prevData,
                        PrefCategories: selectedCategories.length === 0 ? categoryKey : [...selectedCategories, categoryKey].join(","),
                    };
                }
                return prevData;
            });
        },

        handleContentTypeClick = (type: string) => {
            setPersonData((prevData) => {
                const selectedTypes = prevData.PrefContentType ? prevData.PrefContentType.split(",") : [];
                if (selectedTypes.includes(type)) {
                    return {
                        ...prevData,
                        PrefContentType: selectedTypes.filter((t) => t !== type).join(","),
                    };
                } else if (selectedTypes.length < 3) {
                    return {
                        ...prevData,
                        PrefContentType: selectedTypes.length === 0 ? type : [...selectedTypes, type].join(","),
                    };
                }
                return prevData;
            });
        },

        submitPersonData = async () => {
            if (userAuthenticationContext.userData.Person) {
                const
                    completePersonData: IPerson = {
                        ...userAuthenticationContext.userData.Person,
                        ...personData,
                        IsOnboarded: true
                    },
                    token = userAuthenticationContext.userData.Access_Token,
                    abortController = new AbortController();

                try {
                    await AddOrEditPerson(completePersonData, token ? token : "", abortController);
                } catch (error) {
                    console.error("Error submitting preferred categories:", error);
                }
            }
        },

        handleChangeHowDidYouHear = (value: string) => {
            setPersonData((prevData) => ({
                ...prevData,
                HowDidYouHear: value,
            }));
        },

        handleDropdownToggle = () => {
            setTimeout(() => {
                updateWrapperHeight();
            }, 5);
        },

        handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node) && allowClosePopUp) {
                handleClose();
            }
        },

        handleClose = () => {
            submitPersonData();
            setOnBoardPopUpActive(false);
        },

        handleNextStep = () => {
            submitPersonData();
            if (currentStep + 1 < stepCount) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        },

        handlePreviousStep = () => {
            if (currentStep > 0) {
                setCurrentStep((prevStep) => prevStep - 1);
            }
        },

        updateWrapperHeight = () => {
            if (wrapperRef.current && stepRefs.current[currentStep]) {
                const paddingOffset = window.innerWidth >= 584 ? 64 : 32;
                const activeStepHeight = stepRefs.current[currentStep].offsetHeight;
                wrapperRef.current.style.height = `${activeStepHeight + paddingOffset}px`;
            }
        };

    useEffect(() => {
        const checkIfUserIsOnboarded = async () => {
            try {
                const boolResponse = await GetIsOnboarded(userAuthenticationContext.userData.AspNetUserId);
                if (boolResponse === false) {
                    setOnBoardPopUpActive(true);
                }
            } catch (error) {
                console.error("Error checking user onboarded status:", error);
            }
        }

        // For debugging only
        //setOnBoardPopUpActive(true);

        if (userAuthenticationContext.userData.AspNetUserId) {
            setPersonData({
                ...personData,
                ...userAuthenticationContext.userData.Person
            });

            checkIfUserIsOnboarded();
        }
    }, [userAuthenticationContext.userData]);

    useEffect(() => {
        if (onBoardPopUpActive) {
            document.addEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [onBoardPopUpActive]);

    useEffect(() => {
        updateWrapperHeight();

        const handleResize = () => {
            updateWrapperHeight(); // Recalculate height on window resize
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [currentStep]);

    return (
        <div className={`ickonic--user-onboard ${onBoardPopUpActive ? 'is-active' : 'is-not-active'} ${props.isDarkMode ? 'is-dark-mode' : 'is-light-mode'}`}>
            <div ref={popupRef}>
                <div className="user-onboard__wrapper u-relative_hidden" ref={wrapperRef}>
                    <div className="user-onboard__wrapper-window u-relative_hidden">
                        <div
                            className={`user-onboard__steps user-onboard__steps--${currentStep}`}
                            style={{
                                transform: `translateX(-${100 / stepCount * currentStep}%)`,
                                width: `${stepCount}00%`,
                            }}
                        >
                            <div
                                className={`user-onboard__step user-onboard__step-one ${currentStep === 0 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[0] = el!)}
                            >
                                <h1>Hey Family! ... Question Everything!</h1>
                                <h3>Welcome to bold new informative content at your fingertips</h3>
                                <div className="user-onboard__divider"/>
                                <p>Tell us what categories are most interesting to you (max 3):</p>
                                <div className="multi-select-buttons">
                                    {Object.entries(ICategories).map(([key, category]) => (
                                        <button
                                            key={key}
                                            className={`ickonic-button ${personData.PrefCategories?.split(",").includes(key) ? 'is-active' : 'is-not-active'} ${personData.PrefCategories && personData.PrefCategories?.split(",").length >= 3 && !personData.PrefCategories?.split(",").includes(key) ? 'dimmed' : 'is-not-dimmed'}`}
                                            onClick={() => handleCategoryClick(key)}
                                            style={{
                                                boxShadow: `inset 0 0 0 ${personData.PrefCategories && personData.PrefCategories?.split(",").includes(key) ? '2px' : '1px'} ${personData.PrefCategories?.split(",").includes(key) ? category.colour : 'rgba(255,255,255,0.3)'}`,
                                                opacity: personData.PrefCategories && personData.PrefCategories?.split(",").length >= 3 && !personData.PrefCategories?.split(",").includes(key) ? 0.2 : 1,
                                            }}
                                        >
                                            {category.primaryName}
                                        </button>
                                    ))}
                                </div>

                                <ButtonPill
                                    label="Next Step"
                                    className={`ickonic-button__next-step ${personData.PrefCategories !== '' && personData.PrefCategories !== null ? 'is-active' : 'is-not-active'}`}
                                    link=""
                                    callbackFunction={personData.PrefCategories !== '' && personData.PrefCategories !== null ? handleNextStep : () => {
                                    }}
                                />
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-two ${currentStep === 1 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[1] = el!)}
                            >
                                <div className="user-onboard__heading-wrapper">
                                    <h2>How do you prefer to consume content?</h2>
                                    {/*<span className="user-onboard__close" onClick={handleClose}>Skip for now</span>*/}
                                </div>

                                <div className="user-onboard__divider"/>
                                <div className="multi-select-buttons">
                                    {contentTypeOptions.map((type, index) => (
                                        <button
                                            key={index}
                                            className={`ickonic-button ${personData.PrefContentType?.split(",").includes(type) ? 'is-active' : 'is-not-active'} ${personData.PrefContentType && personData.PrefContentType?.split(",").length >= 3 && !personData.PrefContentType?.split(",").includes(type) ? 'dimmed' : 'is-not-dimmed'}`}
                                            onClick={() => handleContentTypeClick(type)}
                                            style={{
                                                boxShadow: `inset 0 0 0 ${personData.PrefContentType && personData.PrefContentType?.split(",").includes(type) ? '2px' : '1px'} ${personData.PrefContentType?.split(",").includes(type) ? Colours.IckonicPink : 'rgba(255,255,255,0.3)'}`,
                                                opacity: personData.PrefContentType && personData.PrefContentType?.split(",").length >= 3 && !personData.PrefContentType?.split(",").includes(type) ? 0.2 : 1,
                                            }}
                                        >
                                            {type}
                                        </button>
                                    ))}
                                </div>

                                <div className="user-onboard__button-wrapper">
                                    <ButtonPill
                                        label="Back"
                                        link=""
                                        className="ickonic-button__prev-step ickonic-button__half-width"
                                        callbackFunction={handlePreviousStep}
                                    />

                                    <ButtonPill
                                        label="Next"
                                        link=""
                                        className={`ickonic-button__next-step ickonic-button__half-width ${personData.PrefContentType !== '' && personData.PrefContentType !== null ? 'is-active' : 'is-not-active'}`}
                                        callbackFunction={personData.PrefContentType !== '' && personData.PrefContentType !== null ? handleNextStep : () => {}}
                                    />
                                </div>
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-three ${currentStep === 2 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[2] = el!)}
                            >
                                <div className="user-onboard__heading-wrapper">
                                    <h2>How did you hear about us?</h2>
                                    {/*<span className="user-onboard__close" onClick={handleClose}>Skip for now</span>*/}
                                </div>

                                <div className="user-onboard__divider"/>

                                <InputDropDown
                                    options={referrerOptions}
                                    selectedValue={personData.HowDidYouHear || ""}
                                    onChange={value => handleChangeHowDidYouHear(value)}
                                    onClick={handleDropdownToggle}
                                    absolutePosition={false}
                                    placeholder="Select an option"
                                />

                                <div className="user-onboard__button-wrapper">
                                    <ButtonPill
                                        label="Back"
                                        link=""
                                        className="ickonic-button__prev-step ickonic-button__half-width"
                                        callbackFunction={handlePreviousStep}
                                    />

                                    <ButtonPill
                                        label="Finish"
                                        link=""
                                        className={`ickonic-button__finish-step ickonic-button__half-width ${personData.HowDidYouHear !== '' && personData.HowDidYouHear !== null ? 'is-active' : 'is-not-active'}`}
                                        callbackFunction={personData.HowDidYouHear !== '' && personData.HowDidYouHear !== null ? handleNextStep : () => {
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-four ${currentStep === 3 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[3] = el!)}
                            >
                                <div className={`user-onboard__gradient-circle ${currentStep === 3 ? 'is-active' : 'is-not-active'}`}>
                                    <div className="user-onboard__gradient-mask"></div>
                                    <div className="user-onboard__gradient-cover"></div>
                                    {IconCheckMark({
                                        defs: (
                                            <defs>
                                                <linearGradient id="grad1" x1="0%" y1="100%" x2="100%" y2="0%">
                                                    <stop offset="0%" stopColor="rgba(229,174,44,1)" stopOpacity="1"/>
                                                    <stop offset="100%" stopColor="rgba(187,54,184,1)" stopOpacity="1"/>
                                                </linearGradient>
                                            </defs>
                                        ),
                                        iconFill: 'none',
                                        stroke: 'url(#grad1)'
                                    })}
                                </div>

                                <div className="user-onboard__heading-wrapper">
                                    <h1>Thank you for your submission!</h1>
                                </div>

                                <p>
                                    We'll use this information maximize your Ickonic experience. Until then, let's get
                                    you streaming!
                                </p>

                                <ButtonPill
                                    label="Start Watching"
                                    link=""
                                    className={`ickonic-button__finish-step is-active`}
                                    callbackFunction={handleClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserOnboarding;
