// src/Components/VideoAccessOverlay.tsx
import React from "react";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import ButtonPill from "../Buttons/ButtonPill";
import {IUserDetails} from "../../Models/Interfaces/IUserDetails";
import {IsActive} from "../../Helpers/Account";
import {RoutePaths} from "../../Constants/RoutePaths";
import EmailFreeView from "./EmailFreeView";

interface VideoAccessOverlayProps {
    video: IVideoDTO;
    isFreeView: boolean;
    userData: IUserDetails;
    doesTokenExist: boolean;
    playbackAllowed: boolean;
    onAccessGranted: () => void;
}

const VideoAccessOverlay: React.FC<VideoAccessOverlayProps> = ({ video, isFreeView, userData, doesTokenExist, playbackAllowed, onAccessGranted }) => {
    let content;

    if (video && video.Id) {
        if (!doesTokenExist) {
            if (video.IsFreeView && !playbackAllowed) {
                content = (
                    <div className="access-overlay__content">
                        <h2>This Video is Free!</h2>

                        <p>
                            Get instant access using your email address:
                        </p>

                        <EmailFreeView
                            onAccessGranted={onAccessGranted}
                        />
                    </div>
                );
            } else {
                content = (
                    <div className="access-overlay__content">
                        <h2>Sign Up to Watch</h2>

                        <p>
                            Unlock This Video for only £1.99!
                        </p>

                        <ButtonPill
                            label="Get Started"
                            className=""
                            link={RoutePaths.SignUp[0]}
                        />
                    </div>
                );
            }
        } else if (userData.AspNetUserId && !IsActive(userData)) {
            content = (
                <div className="access-overlay__content">
                    <h2>Don't miss out!</h2>

                    <p>
                        There is a problem with your account.
                    </p>

                    <ButtonPill
                        label="Manage Subscription"
                        className=""
                        link={RoutePaths.Account}
                    />
                </div>
            );
        }
    }

    return (
        <div
            className="watch-video__access-overlay"
        >
            <div className="access-overlay__inner">
                {content}
            </div>
        </div>
    );
};

export default VideoAccessOverlay;
