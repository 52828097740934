import React, {useState, forwardRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';

import {
    StripeCardNumberElementOptions,
    StripeCardExpiryElementOptions,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
    StripeCardCvcElementChangeEvent
} from "@stripe/stripe-js";
import {StripeInputType} from "../../Models/Enums/StripeInputType";


import {zipValidation} from "../../Helpers/Numbers";

interface CreditCardInputProps {
    name: string;
    value: string;
    label: string;
    onFocus: (name: string) => void;
    onBlur: (name: string) => void;
    isDarkMode: boolean;
    hasError?: boolean;
    errorMessage?: string;
    required?: boolean;
    setCardComplete: (complete: boolean) => void;
    updateZipCode: (newZip: string) => void;
}

const InputsStripe = forwardRef<HTMLInputElement, CreditCardInputProps>(({
    name,
    value,
    label,
    onFocus,
    onBlur,
    isDarkMode,
    hasError,
    errorMessage,
    required,
    setCardComplete,
    updateZipCode
}, ref) => {
    const

        /**
         * Local state
         */
        [focusedInput, setFocusedInput] = useState<string>(""),
        [stripeCardNumberComplete, setStripeNumberComplete] = useState<boolean>(false),
        [stripeExpiryComplete, setStripeExpiryComplete] = useState<boolean>(false),
        [stripeCardCvcComplete, setStripeCvcComplete] = useState<boolean>(false),
        [zipCode, setZipCode] = useState<string>(""),
        [stripeCardZipComplete, setStripeZipComplete] = useState<boolean>(false),

        /**
         * Hooks
         */
        { t } = useTranslation(),

        /**
         * Stripe input options
         */
        modeSpecificStyles = {
            base: {
                color: isDarkMode ? 'white' : 'black',
                '::placeholder': {
                    color: '#888888',
                    fontSize: isDarkMode ? '' : '#808080FF'
                }
            },
        },

        options: StripeCardNumberElementOptions = {
            iconStyle: 'solid',
            showIcon: true,
            style: modeSpecificStyles
        },

        optionsNoIcon: StripeCardExpiryElementOptions = {
            style: modeSpecificStyles
        },

        /**
         * Handlers
         */
        handleStripeChange = (
            event:
                | StripeCardNumberElementChangeEvent
                | StripeCardExpiryElementChangeEvent
                | StripeCardCvcElementChangeEvent
        ) => {
            let typeText = "";
            let InputType;

            switch (event.elementType) {
                case "cardNumber":
                    typeText = "number";
                    InputType = StripeInputType.Number;
                    setStripeNumberComplete(event.complete);
                    break;
                case "cardCvc":
                    typeText = "security code";
                    InputType = StripeInputType.Cvc;
                    setStripeCvcComplete(event.complete);
                    break;
                case "cardExpiry":
                    typeText = "expiration date";
                    InputType = StripeInputType.Expire;
                    setStripeExpiryComplete(event.complete);
                    break;
            }
        },

        handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const
                zip = event.target.value,
                validationResult = zipValidation(zip, t);

            setZipCode(zip);
            updateZipCode(zip);

            if (validationResult === "valid") {
                setStripeZipComplete(true);
            } else {

                setStripeZipComplete(false);
            }
        },

        handleFocus = () => {
            setFocusedInput(name);
            onFocus(name);
        },

        handleBlur = () => {
            setFocusedInput(name);
            onBlur(name);
        };

    useEffect(() => {

        /**
         * If each element in the stripe form has a complete status,
         * we can alert the parent component that the form is ready
         * to submit.
         */
        setCardComplete(stripeCardNumberComplete && stripeCardCvcComplete && stripeExpiryComplete && stripeCardZipComplete);
    }, [stripeCardNumberComplete, stripeCardCvcComplete, stripeExpiryComplete, stripeCardZipComplete]);

    return (
        <div className={`form__input-wrapper form__credit-card ${hasError ? 'has-error' : 'has-no-error'} ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'} ${focusedInput ? "is-focused" : "is-not-focused"} ${value !== '' ? 'has-value' : 'has-no-value'}`}>

            {/** Credit Card # **/}
            <CardNumberElement
                id="cardNumber"
                options={options}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {/** Credit Card Expiry **/}
            <CardExpiryElement
                id="expiry"
                options={optionsNoIcon}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {/** Credit Card CVC **/}
            <CardCvcElement
                id="cvc"
                options={optionsNoIcon}
                onChange={handleStripeChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            <input
                type="text"
                className="form__zip-code"
                onChange={handleZipChange}
                value={zipCode}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="ZIP"
            />
        </div>
    );
});

export default InputsStripe;
